<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card no-body>
      <b-card-header class="d-block">
        <b-card-title>
          {{ lang("t_snapshots") }}
        </b-card-title>
      </b-card-header>

      <b-card-body class="px-0">
        <b-card-text class="d-flex mx-2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model="searchQuery"
              :placeholder="lang('t_search')"
            ></b-form-input>
          </b-input-group>
        </b-card-text>
        <b-table
          :tbody-tr-class="rowClassSnapshot"
          @row-dblclicked="changeSnapshot"
          :filter="searchQuery"
          small
          hover
          :items="snapshots.data"
          :fields="snapshots_fields"
          responsive
          show-empty
          :empty-text="lang('t_noRecord')"
          :busy="snapshots.data.length == 0"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #empty="scope">
            <b-container class="mt-5">
              <b-row>
                <b-col class="text-center text-muted mt-50">
                  <span>{{ scope.emptyText }}</span>
                </b-col>
              </b-row>
            </b-container>
          </template>
          <template #cell(FirstCallDate)="data">
            {{
              new Date(data.value).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })
            }}
          </template>
          <template #cell(LastCallDate)="data">
            {{
              new Date(data.value).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })
            }}
          </template>
          <template #cell(SnapshotDate)="data">
            {{
              new Date(data.value).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })
            }}
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer class="py-75 border-0">
        <div class="d-flex justify-content-between d-flex align-items-center">
          <div class="d-flex align-items-center mb-0">
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="['10', '25', '50']"
              class="mr-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap text-muted font-small-3">
              {{
                lang("t_total") +
                " " +
                snapshots.data.length +
                " " +
                lang("t_row").toLowerCase()
              }}
            </span>
          </div>

          <b-pagination
            size="sm"
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            align="right"
            :total-rows="snapshots.data.length"
            first-number
            last-number
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="16" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="16" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>

    <b-row v-if="selected_snapshot">
      <!-- AGENT -->
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_agent") }}
            </b-card-title>
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="agentSearchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>
            </b-card-text>
            <b-table
              :filter="agentSearchQuery"
              small
              hover
              :items="selected_snapshot.Result.Agent"
              :fields="keyword_fields"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="snapshots.data.length == 0"
              :per-page="agentPerPage"
              :current-page="agentCurrentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(density)="data">
                <b-progress
                  show-value
                  :value="
                    (data.item.callcount * 100) /
                    selected_snapshot.TotalCallCount
                  "
                  :max="100"
                  :variant="
                    parseInt(
                      (data.item.callcount / selected_snapshot.TotalCallCount) *
                        100
                    ) > 40
                      ? 'success'
                      : 'warning'
                  "
                />
              </template>
              <template #cell(label)="data">
                <b-link href="#" @click="fetchRecords(data.value, 'Agent')">{{
                  data.value
                }}</b-link>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  dropleft
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                  toggle-class="py-0"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    @click="displayDensityStatistics(data.item, 'Agent')"
                  >
                    <i class="bi bi-graph-up"></i>
                    <span class="align-middle ml-50">{{
                      lang("t_displayDensityStatistics")
                    }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <b-form-select
                  size="sm"
                  v-model="agentPerPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    selected_snapshot.Result.Agent.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="agentCurrentPage"
                :per-page="agentPerPage"
                align="right"
                :total-rows="selected_snapshot.Result.Agent.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
      <!-- CUSTOMER -->
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_customer") }}
            </b-card-title>
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="customerSearchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>
            </b-card-text>
            <b-table
              :filter="customerSearchQuery"
              small
              hover
              :items="selected_snapshot.Result.Customer"
              :fields="keyword_fields"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="snapshots.data.length == 0"
              :per-page="customerPerPage"
              :current-page="customerCurrentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(density)="data">
                <b-progress
                  show-value
                  :value="
                    (data.item.callcount * 100) /
                    selected_snapshot.TotalCallCount
                  "
                  :max="100"
                  :variant="
                    parseInt(
                      (data.item.callcount / selected_snapshot.TotalCallCount) *
                        100
                    ) > 40
                      ? 'success'
                      : 'warning'
                  "
                />
              </template>
              <template #cell(label)="data">
                <b-link
                  href="#"
                  @click="fetchRecords(data.value, 'Customer')"
                  >{{ data.value }}</b-link
                >
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  dropleft
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                  toggle-class="py-0"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    @click="displayDensityStatistics(data.item, 'Customer')"
                  >
                    <i class="bi bi-graph-up"></i>
                    <span class="align-middle ml-50">{{
                      lang("t_displayDensityStatistics")
                    }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <b-form-select
                  size="sm"
                  v-model="customerPerPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    selected_snapshot.Result.Customer.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="customerCurrentPage"
                :per-page="customerPerPage"
                align="right"
                :total-rows="selected_snapshot.Result.Customer.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-row :class="[displaySound ? 'mb-5' : '']">
      <b-col :cols="displaySound ? '8' : '12'">
        <b-card style="height: 62vh; overflow-y: auto" no-body class="">
          <b-row class="mx-0 mt-1">
            <b-col class="d-flex align-items-center">
              <h4 class="my-0 pl-1">Kayıtlar</h4>
            </b-col>
            <b-col cols="8">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQueryRecords"
                  placeholder="Konuşma, Müşteri Adı, Telefon vs..."
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-card-body>
            <div style="display: grid; overflow-x: auto">
              <b-table
                :tbody-tr-class="rowClassRecords"
                :filter="searchQueryRecords"
                style="min-height: 28.85rem; overflow: inherit"
                small
                @row-dblclicked="load_sound"
                ref="refInvoiceListTable"
                :items="records"
                responsive
                hover
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
                class="position-relative mt-"
              >
                <template #cell(StationName)="data">
                  {{ data.value.split("_")[0] }}
                </template>
                <template #cell(StartDate)="data">
                  {{ data.value.replace("T", " ").slice(0, 19) }}
                </template>
                <template #cell(PhoneNumber)="data">
                  {{ data.value.slice(-10) }}
                </template>
                <template #cell(QueueName)="data">
                  <b-badge variant="light-primary">
                    {{
                      queues.find((e) => e.internal_name == data.value)
                        ? queues.find((e) => e.internal_name == data.value)
                            .display_name
                        : data.value
                    }}
                  </b-badge>
                </template>

                <template #cell(Inbound)="data">
                  <div class="d-flex align-items-center">
                    <b-icon
                      icon="telephone-inbound"
                      v-if="data.value == true"
                    ></b-icon>
                    <b-icon icon="telephone-outbound" v-else></b-icon>

                    <span class="ml-50">{{
                      data.value ? "inbound" : "outbound"
                    }}</span>
                  </div>
                </template>
                <template #cell(AutoEvaluations)="data">
                  {{ getAutoEvaluationsName(data.value) }}
                </template>
                <template #cell(actions)="data">
                  <b-dropdown
                    boundary="scrollParent"
                    :popper-opts="{
                      modifiers: {
                        computeStyle: { gpuAcceleration: false },
                      },
                    }"
                    dropleft
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'email')"
                    >
                      <i class="bi bi-envelope"></i>
                      <span class="align-middle ml-50">E-Posta</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'add_info')"
                    >
                      <i class="bi bi-patch-plus"></i>
                      <span class="align-middle ml-50">Bilgi Ekle</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'special_info')"
                    >
                      <i class="bi bi-person-bounding-box"></i>
                      <span class="align-middle ml-50">Özel Bilgi</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'listens')"
                    >
                      <i class="bi bi-play-btn"></i>
                      <span class="align-middle ml-50">Dinlemeler</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'speech_analatics')"
                    >
                      <i class="bi bi-clipboard-data"></i>
                      <span class="align-middle ml-50"
                        >Konuşma İstatistikleri</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        getCallDetails(data.item, 'sentimental_analatics')
                      "
                    >
                      <i class="bi bi-bar-chart"></i>
                      <span class="align-middle ml-50"
                        >Duygu İstatistikleri</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'media_analatics')"
                    >
                      <i class="bi bi-diagram-3"></i>
                      <span class="align-middle ml-50"
                        >Medya İstatistikleri</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="getCallDetails(data.item, 'signal_logs')"
                    >
                      <i class="bi bi-reception-2"></i>

                      <span class="align-middle ml-50"
                        >Sinyalleşme Logları</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </b-card-body>
          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPageRecords"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    totalRows +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                @input="fetchRecords()"
                size="sm"
                class="mb-0"
                v-model="currentPageRecords"
                :per-page="perPageRecords"
                align="right"
                :total-rows="totalRows"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col v-if="displaySound" cols="4">
        <b-card
          ref="soundCard"
          style="height: 62vh; overflow-y: auto"
          no-body
          class="mb-0 border-bottom"
        >
          <div>
            <b-row v-if="selected_record" class="border-bottom mx-0">
              <b-col>
                <b-row align-h="between" align-v="center">
                  <b-col>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <b-avatar size="38" variant="light-primary" class="my-1">
                        {{
                          selected_record.StationName[0].toUpperCase() +
                          selected_record.StationName.split(
                            "."
                          )[1][0].toUpperCase()
                        }}
                      </b-avatar>
                      <!-- <span class="ml-1 font-weight-bold">{{
                          selected_record.StationName.split("_")[0]
                        }}</span> -->

                      <!-- <span>{{ displayKeyWordCategories() }}</span> -->
                      <!-- <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        :disabled="
                          Object.keys(categorized_messages).length == 0
                        "
                        class="ml-1"
                        size="sm"
                      >
                        <template #button-content>
                          <i class="bi bi-tags font-medium-5"></i>
                        </template>

                        <b-dropdown-item
                          @click="
                            searchKeyword = categorized_messages[item].keyword;
                            searchAndHighlightKeyword();
                          "
                          v-for="(item, i) in Object.keys(categorized_messages)"
                          :key="i"
                        >
                          {{ item }}
                        </b-dropdown-item>
                      </b-dropdown> -->

                      <b-form-input
                        size="sm"
                        class="mx-1 rounded"
                        v-model="searchKeyword"
                        @keyup="searchAndHighlightKeyword"
                      />
                      <span class="ml-1">
                        {{ totalMatches == 0 ? "0" : currentMatchIndex + 1 }}/{{
                          totalMatches
                        }}
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>

          <div>
            <!-- <div style="height: 70vh; overflow-y: auto"> -->
            <div ref="msg_div" class="messages mt-1">
              <div v-for="(message, i) in messages" :key="i">
                <b-row class="px-1">
                  <b-col>
                    <span
                      :class="[
                        message.type == 'customer'
                          ? 'msg_from bg-light-secondary'
                          : 'msg_to bg-light-primary',
                        checkKeywordCategory(message.Text) == true &&
                        message.type == 'customer'
                          ? 'border-secondary'
                          : checkKeywordCategory(message.Text) == true &&
                            message.type == 'agent'
                          ? 'border-primary'
                          : '',
                      ]"
                    >
                      <span
                        v-for="(word, wordIndex) in message.Words"
                        :key="wordIndex"
                        :class="{
                          highlight: isWordPlaying(word) || word.highlight,
                          'font-weight-bolder': word.overlapping,
                        }"
                      >
                        {{ word.Name }}
                      </span>

                      <small class="time">
                        <div
                          class="d-flex align-items-center justify-content-end gap-1"
                        >
                          <i
                            v-if="message.Sentiment.Result == 1"
                            class="bi bi-record-circle text-danger"
                          ></i>
                          <i
                            v-if="message.Sentiment.Result == 2"
                            class="bi bi-record-circle text-success ml-50"
                          ></i>
                          <i
                            v-if="message.HighGainDuration > 0"
                            class="bi bi-graph-up-arrow text-warning ml-50"
                          ></i>
                          <i
                            v-if="message.LowGainDuration > 0"
                            class="bi bi-graph-down-arrow text-danger ml-50"
                          ></i>
                          <span class="ml-1">{{ message.Start }}</span>
                        </div>
                      </small>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      :class="displaySound ? 'd-block' : 'd-none'"
      style="
        position: fixed;
        bottom: 0;
        z-index: 9999;
        left: 0;
        width: 100vw;
        margin-left: 0;
        margin-right: 0;
      "
    >
      <b-col class="px-0">
        <b-card class="mb-0">
          <b-card-text>
            <b-row class="mx-0">
              <b-col
                class="d-flex align-items-center justify-content-center px-0"
              >
                <b-dropdown
                  v-if="wavesurfer"
                  class="mr-50 ml-2"
                  :text="wavesurfer.getPlaybackRate() + 'x'"
                  variant="flat-secondary"
                >
                  <b-dropdown-item @click="setSoundSpeed(0.25)">
                    0.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.5)">
                    0.5x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.75)">
                    0.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1)">
                    1x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.25)">
                    1.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.5)">
                    1.50x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.75)">
                    1.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(2)">
                    2x
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipBackIcon" />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="btn-icon rounded-circle mx-50"
                  @click="
                    () => {
                      wavesurfer.playPause();
                    }
                  "
                >
                  <feather-icon
                    v-if="wavesurfer"
                    size="20"
                    :icon="
                      wavesurfer && wavesurfer.isPlaying()
                        ? 'PauseIcon'
                        : 'PlayIcon'
                    "
                  />
                </b-button>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipForwardIcon" />
                </b-button>
                <span style="width: 3rem" class="ml-3">
                  {{ `${formatTime(parseInt(currentTime))}` }}
                </span>
                <b-col class="cursor-pointer" :id="`fdwaveform`"></b-col>
                <span
                  style="width: 3rem"
                  v-if="wavesurfer && wavesurfer.decodedData"
                >
                  {{
                    `${formatTime(parseInt(wavesurfer.decodedData.duration))}`
                  }}
                </span>
                <b-button
                  @click="closeSound"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle ml-5"
                >
                  <feather-icon size="20" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      @shown="onModalShown"
      v-model="modal_density_statistics"
      centered
      hide-footer
      :title="modal_density_statistics_title"
      body-class="px-0 py-0"
      dialog-class="dClass75"
    >
      <b-row>
        <b-col>
          <b-card class="card-tiny-line-stats mb-0" body-class="pb-50">
            <vue-apex-charts
              height="400"
              :options="density_statistics.chartOptions"
              :series="chart_series"
              :key="chart_key"
              ref="apexChart"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      title="Sinyalleşme Logları"
      v-if="selected_record_details"
      v-model="modals.signal_logs"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.SignalingLogs"
          :fields="signal_logs_fields"
        >
          <template #cell(MessageDate)="data">
            {{ new Date(data.value).toLocaleTimeString() }}
          </template>
          <template #cell(Message)="data">
            <b-link @click="openSignalLogDetails(data.item)">{{
              data.item.Request ? data.item.Request : data.item.Response
            }}</b-link>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      title="Medya İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.media_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div
        class="d-flex align-items-center justify-content-center flex-wrap"
        style="gap: 8px"
      >
        <b-card
          v-for="(item, i) in [
            'RtpPacketCountIn',
            'RtpPacketCountOut',
            'DublicateRtpPacketCountIn',
            'DublicateRtpPacketCountOut',
            'LostRtpPacketCountIn',
            'LostRtpPacketCountOut',
            'FirstRtpPacketIn',
            'FirstRtpPacketOut',
            'LastRtpPacketIn',
            'LastRtpPacketOut',
            'CodecIn',
            'CodecOut',
            'RtpEncryptionIn',
            'RtpEncryptionOut',
          ]"
          class="bg-light-primary"
          no-body
          style="flex-basis: calc(100% / 7 - 8px)"
          :key="i"
        >
          <div class="text-center py-1 px-1">
            <span
              v-b-tooltip.hover.top="item"
              class="d-inline-block text-truncate"
              style="max-width: 99%"
              >{{ item }}</span
            >

            <h2
              v-if="
                [
                  'FirstRtpPacketIn',
                  'FirstRtpPacketOut',
                  'LastRtpPacketIn',
                  'LastRtpPacketOut',
                ].includes(item)
              "
            >
              {{
                new Date(
                  selected_record_details.MediaLog[item]
                ).toLocaleTimeString()
              }}
            </h2>
            <h2
              v-else-if="
                ['CodecIn', 'CodecOut'].includes(item) &&
                selected_record_details.MediaLog[item].length == 0
              "
            >
              {{ "-" }}
            </h2>

            <h2 v-else>
              {{ selected_record_details.MediaLog[item] }}
            </h2>
          </div>
        </b-card>
      </div>
      <div class="border-bottom">
        <h2 class="text-primary">Sources In</h2>
      </div>
      <b-row class="py-1">
        <b-col cols="6">
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].IpPortLen }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].FirstDate }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].LastDate }}
          </span>
        </b-col>
      </b-row>
      <div class="border-bottom">
        <h2 class="text-primary">Sources Out</h2>
      </div>
      <b-row class="py-1">
        <b-col cols="6">
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].IpPortLen }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].FirstDate }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].LastDate }}
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Duygu İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.sentimental_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <b-row>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Temsilci</span
              >
              <small class="text-muted">Temsilci konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in sentiment_object"
              :key="i"
              class="mt-auto"
              :class="[
                [
                  'SentimentPositiveSegmentRate',
                  'SentimentNegativeSegmentRate',
                ].includes(item.internal_name)
                  ? 'mb-3'
                  : 'mb-1',
              ]"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[1][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Müşteri</span
              >
              <small class="text-muted">Müşteri konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in sentiment_object"
              :key="i"
              class="mt-auto"
              :class="[
                [
                  'SentimentPositiveSegmentRate',
                  'SentimentNegativeSegmentRate',
                ].includes(item.internal_name)
                  ? 'mb-3'
                  : 'mb-1',
              ]"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[0][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Konuşma İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.speech_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <b-row v-if="selected_record_details">
        <b-col
          v-for="(item, i) in speech_object2"
          :key="i"
          md="2"
          sm="2"
          class="mt-auto mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media class="mt-2 mb-75" no-body>
            <b-media-aside class="mr-2">
              <b-avatar rounded="lg" size="64" :variant="item.color">
                <!-- <feather-icon size="28" :icon="item.icon" /> -->
                <i :class="[item.icon, 'font-medium-3']"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h2 class="font-weight-bolder mb-0">
                {{
                  parseInt(selected_record_details.Speech[item.internal_name])
                }}
              </h2>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Temsilci</span
              >
              <small class="text-muted">Temsilci konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in speech_object"
              :key="i"
              class="mt-auto mb-1"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[1][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Müşteri</span
              >
              <small class="text-muted">Müşteri konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in speech_object"
              :key="i"
              class="mt-auto mb-1"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[0][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Bilgi Ekle"
      v-if="selected_record_details"
      v-model="modals.add_info"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          style="max-height: 400px; overflow-y: auto"
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.Attachments"
          :fields="attechments_fields"
        >
          <template #cell(Date)="data">
            <span class="text-nowrap">
              {{
                new Date(new Date(data.value).getTime() + 3 * 60 * 60 * 1000)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")
              }}
            </span>
          </template>
        </b-table>
      </div>
      <hr />
      <b-row>
        <b-col cols="3">
          <b-form-group label="Saniye">
            <b-form-input
              v-model="attechment_info.Second"
              type="number"
              placeholder="Saniye"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group label="Yorum">
            <b-form-input
              v-model="attechment_info.Comment"
              placeholder="Yorum"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-1">
        <b-button @click="setAttachData" class="ml-auto" variant="primary"
          >Bilgi Ekle</b-button
        >
      </div>
    </b-modal>
    <b-modal
      title="Dinlemeler"
      v-if="selected_record_details"
      v-model="modals.listens"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          style="max-height: 400px; overflow-y: auto"
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.Listens"
          :fields="listens_fields"
        >
          <template #cell(Date)="data">
            <span class="text-nowrap">
              {{
                new Date(new Date(data.value).getTime() + 3 * 60 * 60 * 1000)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")
              }}
            </span>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      title="Özel Bilgi"
      v-if="selected_record_details && customer"
      v-model="modals.special_info"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <customer-details :selected_customer="customer"></customer-details>
    </b-modal>
    <b-modal
      title="Email"
      v-if="selected_record_details"
      v-model="modals.email"
      dialog-class="dClass75"
      centered
      ok-only
      ok-title="Gönder"
      @ok="SendEmail"
    >
      <b-row class="mb-1">
        <b-col>
          <b-form-checkbox
            v-model="email_info.Agent"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("t_agent") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="email_info.SendLink"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Bağlantı") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="email_info.Details"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Detaylar") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="CC">
            <b-form-input
              v-model="email_info.CC"
              placeholder="CC"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Konu">
            <b-form-input
              v-model="email_info.Subject"
              placeholder="Konu"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Açıklama">
            <b-form-textarea
              rows="5"
              v-model="email_info.Explanation"
              placeholder="Açıklama"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BContainer,
  BListGroup,
  BListGroupItem,
  BCardSubTitle,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BProgressBar,
  BProgress,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BCardFooter,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BIcon,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
const $trackBgColor = '#EBEBEB'
import { $themeColors } from '@themeConfig'
import CustomerDetails from '../CustomerDetails.vue';
const startDate = new Date();
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 55, 0, 0);

export default {
  components: {
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    CustomerDetails,
    VueApexCharts,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BContainer,
    BListGroup,
    BListGroupItem,
    BCardSubTitle,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BProgressBar,
    BProgress,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BCardFooter,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BIcon,
    BFormSelect,
    BModal,
  },

  computed: {

  },
  props: {
    // title: {
    //   type: String,
    //   default: () => (''),
    // },

    selected_trend: {
      type: Object,
      default: () => ({}),
    },
    // selected_snapshot: {
    //   type: Object,
    //   default: () => ({}),
    // },
    snapshots: {
      type: Object,
      default: () => ({}),
      // type: Array,
      // default: () => ([]),
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      is_progress: false,
      modal_density_statistics: false,
      modal_density_statistics_title: '',
      selected_snapshot: null,
      searchQuery: '',
      agentSearchQuery: '',
      customerSearchQuery: '',
      perPage: 10,
      currentPage: 1,
      agentCurrentPage: 1,
      agentPerPage: 10,
      customerCurrentPage: 1,
      customerPerPage: 10,
      currentPageRecords: 1,
      perPageRecords: 10,
      queues: [],
      snapshots_fields: [
        { key: 'SnapshotDate', label: globalThis._lang('t_snapshotDate'), sortable: true },
        { key: 'FirstCallDate', label: globalThis._lang('t_firstCallDate'), sortable: true },
        { key: 'LastCallDate', label: globalThis._lang('t_lastCallDate'), sortable: true },
        { key: 'CallCount', label: globalThis._lang('t_callCount'), sortable: true },
      ],
      keyword_fields: [
        { key: 'label', label: globalThis._lang('t_text'), sortable: true },
        { key: 'callcount', label: globalThis._lang('t_callCount'), sortable: true },
        { key: 'density', label: globalThis._lang('t_density'), sortable: true },
        { key: 'actions', label: '', sortable: false, thStyle: { width: '2vh' } },
      ],
      avg_denstiy: 0,
      chart_key: new Date().getTime(),
      chart_series: [],
      density_statistics: {
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          annotations: {
            yaxis: [
              {
                y: 24,
                borderColor: $themeColors.warning,
                label: {
                  borderColor: $themeColors.warning,
                  style: {
                    color: '#fff',
                    background: $themeColors.warning,
                  },
                  text: globalThis._lang('t_avarageDensity'),
                },
              },
            ],
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            categories: ["2023-12-12", "2023-12-11", "09", "13", "17", "21", "26", "31"],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },

      filter_values: {
        "dateRanges": {
          "startDate": startDate.toISOString(),
          "endDate": endDate.toISOString(),
        },
        "RecordIds": [],
        "Queues": [],
        "Users": [],
        "CallDirections": [],
        "SpeechOption": 1,
        "KeyWordSpottings": [],
        "KeyWordSpottingsLogicRule": "1",
        "TerminateReasons": [],
        "QmForms": [],
        "QmFormsLogicRule": "1",
        "QmFormPointOption": 0,
        "QmFormPoint": null,
        "Evaluators": [],
        "EvaluationDateRanges": {
          "startDate": null,
          "endDate": null,
        },
        "ObjectionOption": 0,
        "SearchAgent": "",
        "SearchCustomer": "",
        "SearchBoth": "",
        "EvaluationOption": "",
        "Listens": 0,
        "Listener": "",
        "DurationMin": 0,
        "DurationMax": 0,
        "PbxCallId": "",
        "RemoteNumber": ""
      },
      SpeechRules: null,
      customer: null,
      modals: {
        email: false,
        add_info: false,
        special_info: false,
        listens: false,
        speech_analatics: false,
        sentimental_analatics: false,
        media_analatics: false,
        signal_logs: false,
        signal_log_details: false,
      },
      sentiment_object: [
        { "internal_name": "SentimentPositiveSegmentCount", "display_name": "Pozitif Segment Sayısı", "icon": "bi bi-emoji-smile", color: 'success' },
        { "internal_name": "SentimentPositiveScore", "display_name": "Pozitif Skor", "icon": "bi bi-graph-up", color: 'success' },
        { "internal_name": "SentimentPositiveTokenCount", "display_name": "Pozitif Token Sayısı", "icon": "bi bi-check", color: 'success' },
        { "internal_name": "SentimentPositiveSegmentRate", "display_name": "Pozitif Segment Oranı (%)", "icon": "bi bi-pie-chart", color: 'success' },
        { "internal_name": "SentimentNegativeSegmentCount", "display_name": "Negatif Segment Sayısı", "icon": "bi bi-emoji-frown", color: 'danger' },
        { "internal_name": "SentimentNegativeScore", "display_name": "Negatif Skor", "icon": "bi bi-graph-down", color: 'danger' },
        { "internal_name": "SentimentNegativeTokenCount", "display_name": "Negatif Token Sayısı", "icon": "bi bi-x", color: 'danger' },
        { "internal_name": "SentimentNegativeSegmentRate", "display_name": "Negatif Segment Oranı (%)", "icon": "bi bi-pie-chart", color: 'danger' },
        { "internal_name": "SentimentNotrSegmentRate", "display_name": "Nötr Segment Oranı (%)", "icon": "bi bi-dash", color: 'secondary' },
        { "internal_name": "SentimentTotalScore", "display_name": "Toplam Skor", "icon": "bi bi-123", color: 'info' },

      ],
      speech_object: [
        { "internal_name": "SpeechDuration", "display_name": "Konuşma Süresi", "icon": "bi bi-clock", "color": "danger" },
        { "internal_name": "SpeechRatio", "display_name": "Konuşma Oranı", "icon": "bi bi-bar-chart", "color": "success" },
        { "internal_name": "SpeechingRate", "display_name": "Konuşma Hızı (Kelime/Dakika)", "icon": "bi bi-speedometer2", "color": "success" },
        { "internal_name": "InterruptCount", "display_name": "Söz Kesme Sayısı", "icon": "bi bi-x-circle", "color": "success" },
        { "internal_name": "LowGainDuration", "display_name": "Monoton Konuşma Süresi", "icon": "bi bi-volume-down", "color": "success" },
        { "internal_name": "HighGainDuration", "display_name": "Ageresif Konuşma Süresi", "icon": "bi bi-exclamation-circle", "color": "danger" }
      ],

      speech_object2: [
        {
          "icon": "bi bi-bar-chart",
          "color": "light-primary",
          "title": "% 0",
          "subtitle": "Üstüste Konuşma Oranı",
          "customClass": "mb-2 mb-xl-0",
          "internal_name": "OverlappedSpeechRatio"
        },
        {
          "icon": "bi bi-clock",
          "color": "light-primary",
          "title": "% 12",
          "subtitle": "Üstüste Konuşma Süresi",
          "customClass": "mb-2 mb-sm-0",
          "internal_name": "OverlappedSpeechDuration"
        },
        {
          "icon": "bi bi-clock-history",
          "color": "light-primary",
          "title": "0",
          "subtitle": "Toplam Üstüste Konuşma Süresi",
          "customClass": "",
          "internal_name": "OverlappedSpeechDuration"
        },
        {
          "icon": "bi bi-activity",
          "color": "light-info",
          "title": "% 0",
          "subtitle": "Sessizlik Oranı",
          "customClass": "mb-2 mb-xl-0",
          "internal_name": "OverlappedSilenceRatio"
        },
        {
          "icon": "bi bi-hourglass-split",
          "color": "light-info",
          "title": "% 12",
          "subtitle": "Sessizlik Süresi",
          "customClass": "mb-2 mb-sm-0",
          "internal_name": "OverlappedSilenceDuration"
        },
        {
          "icon": "bi bi-hourglass-bottom",
          "color": "light-info",
          "title": "0",
          "subtitle": "Toplam Sessizlik Süresi",
          "customClass": "",
          "internal_name": "OverlappedSilenceDuration"
        }
      ],

      signal_logs_fields: [
        { key: 'MessageDate', label: 'MessageDate', sortable: true },
        { key: 'Message', label: 'Message', sortable: true },
        { key: 'From', label: 'From', sortable: true },
        { key: 'To', label: 'To', sortable: true },
        { key: 'Transport', label: 'Transport', sortable: true },
        { key: 'SrcIpPort', label: 'SrcIpPort', sortable: true },
        { key: 'DstIpPort', label: 'DstIpPort', sortable: true },
      ],
      listens_fields: [
        { key: 'Date', label: 'Tarih', sortable: true },
        { key: 'UserId', label: 'Temsilci', sortable: true },
      ],
      attechments_fields: [
        { key: 'Date', label: 'Tarih', sortable: true },
        { key: 'UserId', label: 'Temsilci', sortable: true },
        { key: 'Second', label: 'Saniye', sortable: true },
        { key: 'Comment', label: 'Yorum', sortable: true },
      ],
      attechment_info: {
        Comment: '',
        Second: 0
      },
      email_info: {
        Me: false,
        Agent: false,
        CC: '',
        Subject: 'Çağrı Kaydı Maili',
        SendLink: false,
        Explanation: '',
        Details: false,
      },
      currentTime: 0,
      displaySound: false,
      wavesurfer: null,
      keyword_categories: [],
      messages: [],
      tableColumns: [
        { key: 'Inbound', label: 'Yön', sortable: true, tdClass: 'text-nowrap' },
        { key: 'QueueName', label: 'Kuyruk', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StationName', label: 'Temsilci', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PbxCallId', label: 'Çağrı ID', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PhoneNumber', label: 'Numara', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StartDate', label: 'Tarih', sortable: true, tdClass: 'text-nowrap' },
        { key: 'SipKeyValue', label: 'Sonuç Kodu', sortable: true, tdClass: 'text-nowrap', },
        { key: 'Duration', label: 'Süre', sortable: true, tdClass: 'text-nowrap', },
        { key: 'AutoEvaluations', label: 'Değerlendirmeler', sortable: true, tdClass: 'text-nowrap', },
        { key: 'actions', label: '', thStyle: { width: '5vh' } },
      ],
      records: [],
      speechRegions: [],
      selected_record: null,
      categorized_messages: null,
      searchKeyword: '',
      searchQueryRecords: '',
      currentMatchIndex: 0,
      totalMatches: 0,
      totalRows: 0,
      matchIndices: [],
      selected_record_details: null,
    }
  },
  methods: {
    onModalShown() {
      const chartElement = this.$refs.apexChart;
      if (chartElement && chartElement.chart) {
        chartElement.chart.updateSeries(this.chart_series);
      }
    },
    displayDensityStatistics(item, side) {
      let result = {};

      for (const snapshot of this.snapshots.data.slice(0, 12)) {
        let finded_result = snapshot.Result[side].find(e => e.label == item.label);
        if (finded_result) {
          result[snapshot.SnapshotDate] = Math.round(finded_result.callcount / snapshot.TotalCallCount * 100);
        } else {
          result[snapshot.SnapshotDate] = 0;
        }
      }

      let _categories = [...Object.keys(result)];
      let _data = [...Object.values(result)];
      let sum = _data.reduce((acc, val) => acc + val, 0);

      this.avg_denstiy = Math.round(sum / _data.length);
      this.chart_series = [{ name: globalThis._lang('t_density'), data: _data }];

      this.density_statistics.chartOptions = {
        ...this.density_statistics.chartOptions,
        xaxis: {
          ...this.density_statistics.chartOptions.xaxis,
          categories: _categories
        },

        annotations: {
          ...this.density_statistics.chartOptions.xaxis,
          yaxis: [
            {
              y: this.avg_denstiy,
              borderColor: $themeColors.warning,
              label: {
                borderColor: $themeColors.warning,
                style: {
                  color: '#fff',
                  background: $themeColors.warning,
                },
                text: `${globalThis._lang('t_avarageDensity')}: %${this.avg_denstiy}`,
              },
            },
          ]
        }


      };

      this.modal_density_statistics = true;
      this.modal_density_statistics_title = item.label;

      this.$nextTick(() => {
        this.chart_key = new Date().getTime();

        const chartElement = this.$refs.apexChart;
        if (chartElement && chartElement.chart) {
          chartElement.chart.updateSeries(this.chart_series);
        }
      });
    },

    changeSnapshot(item) {
      console.log("item2", item)
      this.is_progress = true;
      this.selected_snapshot = item;
      this.is_progress = false;
    },
    async confirmText(item) {
      console.log(item);
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          // https://callexper.wrtcdev.voyce.cc/api/TrendAnalytics/addStopWordList/66c8955ced642f8186393e91
          this.is_progress = true;

          var response = (
            await this.$http_in.post(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/addStopWordList/${this.selected_trend._id}`,
              {
                Text: item.label
              }
            )
          ).data;
          this.is_progress = false;
        }
      });
    },

    // ----
    async fetchRecords(word, side) {
      console.log("this.selected_snapshot", this.selected_snapshot);
      this.is_progress = true;

      if (this.filter_values.Queues.length == 0) {
        this.filter_values.Queues = globalThis.queues.map(e => e.display_name);
      }

      this.filter_values.RecordIds = this.selected_snapshot.Records ?? [];
      this.filter_values[`Search${side}`] = word;
      this.filter_values.dateRanges = {
        "startDate": new Date(this.selected_snapshot.FirstCallDate).toISOString(),
        "endDate": new Date(this.selected_snapshot.LastCallDate).toISOString(),
      };
      let _payload = {
        offset: Number(this.currentPageRecords) - 1,
        amount: this.perPageRecords,
        filter_values: this.filter_values
      };

      var response = (await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetRecordsAdvanceFilter`, _payload)).data;
      console.log(response);
      this.records = response.data.map(e => { return { ...e, StationName: e.Station.Name } });
      console.log("this.records", this.records)
      this.totalRows = response.recordsTotal;
      this.is_progress = false;

    },

    setDateFormat(date_string) {
      const date_object = new Date(date_string);

      const day = String(date_object.getDate()).padStart(2, '0');
      const month = String(date_object.getMonth() + 1).padStart(2, '0');
      const year = date_object.getFullYear();
      const hour = String(date_object.getHours()).padStart(2, '0');
      const minute = String(date_object.getMinutes()).padStart(2, '0');

      const formatted_date = `${day}/${month}/${year} ${hour}:${minute}`;
      return formatted_date;
    },
    closeSound() {
      if (this.displaySound) {
        this.displaySound = false;
        var myDiv = document.getElementById(`fdwaveform`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
        this.selected_record = null;
        this.matchIndices = [];
        this.totalMatches = 0;
        this.currentMatchIndex = 0;
        this.searchKeyword = '';

      }

    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },
    async load_sound(item) {
      try {
        this.is_progress = true;
        this.closeSound();
        this.selected_record = item;
        console.log("item", item);
        let _month = new Date(item.StartDate).getMonth()
        var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
        console.log("response123", response);
        this.SpeechRules = response.SpeechRules;

        this.messages = [];
        this.messages = response.Speech.Channels[0].SpeechRegions.map(e => { return { ...e, type: 'customer' } });
        this.messages = [...this.messages, ...response.Speech.Channels[1].SpeechRegions.map(e => { return { ...e, type: 'agent' } })];
        this.messages = this.messages.sort((a, b) => a.Start - b.Start);
        console.log("test1");

        this.messages = this.findAndTagOverlappingWords(this.messages);
        console.log("test2");
        this.categorized_messages = this.categorizeMessages();
        console.log("test3");

        this.displaySound = !this.displaySound;
        console.log("test4");

        if (this.displaySound) {
          console.log("test5");

          this.wavesurfer = globalThis.WaveSurfer.create({

            container: '#fdwaveform',
            waveColor: '#F0F0F1',
            progressColor: $themeColors.primary,
            url: response.RecordingFileName,
            "height": 60,
            barWidth: 4,
            barGap: 3,
            barRadius: 8,
          })
          console.log("this.wavesurfer", this.wavesurfer)

          this.wavesurfer.once('interaction', () => {
            console.log("interaction")
            this.wavesurfer.play()
          })

          this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);
        } else {
          var myDiv = document.getElementById("fdwaveform");
          myDiv.innerHTML = null;
          this.wavesurfer = null;
          this.currentTime = 0;
        }
      } catch (error) {
      } finally {
        this.is_progress = false;
      }

    },
    checkKeywordCategory(word) {
      for (const _word of word.split(' ')) {
        let object = this.SpeechRules.filter(e => e.Result == true).find(r => r.KeyWords.includes(_word));
        // console.log("checkKeywordCategory", _word, object);
        if (object) {
          // console.log("GELDİ")
          return true
          // return this.dashboard_data["ByCategory"][object.Id].Name
        }
      }

      return false;

    },
    displayKeyWordCategories() {
      return this.categorized_messages
        .filter(item => item.categorized_keywords.length > 0)
        .map(item => item.categorized_keywords[0].category);
    },

    getAutoEvaluationsName(data) {
      if (data && data.length > 0) {
        let results = [];
        data.forEach((evaluation, index) => {
          let formPoint = evaluation.Form.Point;
          let result = `O:${index + 1}-${formPoint}`;
          results.push(result);
        });
        return results;
      }
      return '';

    },
    openSignalLogDetails(item) {
      this.$swal({
        title: item.Request ? item.Request : item.Response,
        width: 750,
        html: '<pre style="text-align:left;width:100%;height:300px;">' + item.Message + '</pre>',
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

    },
    async getCallDetails(item, modal) {
      this.is_progress = true;
      let _month = new Date(item.StartDate).getMonth()
      var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
      this.selected_record_details = response;
      this.selected_record = item;
      if (modal) {
        if (modal == 'special_info') {
          await this.GetCustomerByInteractionID(item);
        }
        this.modals[modal] = true;
      }
      this.is_progress = false;
    },
    async GetCustomerByInteractionID(item) {
      var response = (await this.$http_in.get(`spv/v1/Customer/${item.PbxCallId}`)).data;
      this.customer = response;
    },
    async setAttachData() {
      let _month = new Date(this.selected_record.StartDate).getMonth()
      var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/AttachData/${this.selected_record._id}/${_month}`, this.attechment_info)).data;
      if (response.ResultCode == 0) {
        this.getCallDetails(this.selected_record);
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.attechment_info = {
        Second: 0,
        Comment: ''
      };

    },
    async SendEmail() {
      var call_details = (await this.$http_in.get(`spv/v1/CallDetails/${this.selected_record.PbxCallId}`)).data;
      let _url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${call_details.RecordingFile}`;
      let _month = new Date(this.selected_record.StartDate).getMonth();
      var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/SendMail/${this.selected_record._id}/${_month}`, {
        ...this.email_info,
        "Link": _url,
        "Organizations": false,
        "OrganizationId": this.selected_record.Station.OrganizationId,
        "Organization": this.selected_record.Station.OrganizationName,
        "UserId": this.selected_record.Station.Name,
        "Duration": this.selected_record.Duration,
        "QueueExtension": this.selected_record.QueueExtension,
        "StartDate": this.selected_record.StartDate
      })).data;
      if (response.ResultCode == 0) {
        this.getCallDetails(this.selected_record);
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.email_info = {
        Me: true,
        Agent: false,
        CC: '',
        Subject: 'Çağrı Kaydı Maili',
        SendLink: false,
        Explanation: '',
        Details: false,
      };

    },
    searchAndHighlightKeyword(event) {
      if (this.searchKeyword === '') {
        this.clearHighlights();
        return;
      }

      if (event && event.key === 'Enter') {
        if (this.totalMatches > 0) {
          this.currentMatchIndex = (this.currentMatchIndex + 1) % this.totalMatches;
          this.highlightAndScrollToCurrentMatch();
        }
        return;
      }

      this.clearHighlights();

      const keywords = this.searchKeyword.toLowerCase().split(',').filter(e => e.trim() !== '');

      // Find matches
      this.matchIndices = [];
      keywords.forEach(keyword => {
        this.messages.forEach((message, messageIndex) => {
          message.Words.forEach((word, wordIndex) => {
            if (word.Name.toLowerCase() == keyword.trim()) {
              this.$set(word, 'highlight', true);
              this.matchIndices.push({ messageIndex, wordIndex });
            }
          });
        });
      });

      this.totalMatches = this.matchIndices.length;

      if (this.totalMatches > 0) {
        this.currentMatchIndex = 0;
        this.highlightAndScrollToCurrentMatch();
      }
    },

    clearHighlights() {
      this.messages.forEach(message => {
        message.Words.forEach(word => {
          word.highlight = false;
        });
      });
      this.matchIndices = [];
      this.totalMatches = 0;
      this.currentMatchIndex = 0;
    },

    highlightAndScrollToCurrentMatch() {
      if (this.totalMatches > 0) {
        const { messageIndex, wordIndex } = this.matchIndices[this.currentMatchIndex];
        this.scrollToWord(messageIndex, wordIndex);
      }
    },

    scrollToWord(messageIndex, wordIndex) {
      this.$nextTick(() => {
        const messageDiv = this.$refs.msg_div;
        const targetMessages = messageDiv.querySelectorAll('.msg_from, .msg_to');
        const targetMessage = targetMessages[messageIndex];
        const targetWords = targetMessage.querySelectorAll('span');
        const targetWord = targetWords[wordIndex];

        if (targetMessage && targetWord) {
          console.log(`Scrolling to message ${messageIndex}, word ${wordIndex}`);
          console.log(`Message: ${targetMessage.innerText}`);
          console.log(`Word: ${targetWord.innerText}`);

          setTimeout(() => {
            targetWord.scrollIntoView({ behavior: 'smooth', block: 'center', });
          }, 100);
        } else {
          console.warn('Target message or word not found:', targetMessage, targetWord);
        }
      });
    },
    rowClassRecords(item) {
      console.log("item", item);
      if (this.selected_record && this.selected_record.PbxCallId == item.PbxCallId) {
        return "table-primary table-style text-dark font-weight-bolder"
      }
    },
    rowClassSnapshot(item) {
      if (this.selected_snapshot && this.selected_snapshot.SnapshotDate == item.SnapshotDate) {
        return "table-primary table-style text-dark font-weight-bolder"
      }
    },
    categorizeMessages() {
      console.log("categorizeMessages called"); // Kontrollü adım
      try {
        const allKeywords = this.keyword_categories.flatMap(rule => {
          return rule.rules.flatMap(r => {
            return r.keywords.map(keyword => ({
              ...keyword,
              category: rule.display_name,
            }));
          });
        });

        const categorizedMessages = this.messages.map(message => {
          const categorizedWords = message.Words.map(word => {
            const matchedKeyword = allKeywords.find(kw => {
              const inFirstSeconds = kw.in_first_seconds === 0 || word.Start <= kw.in_first_seconds;
              const inLastSeconds = kw.in_last_seconds === 0 || word.End >= kw.in_last_seconds;
              const occurrenceCount = message.Words.filter(w => w.Name === kw.keyword).length;
              const meetsOccurrence =
                (kw.min_occurrence === 0 || occurrenceCount >= kw.min_occurrence) &&
                (kw.max_occurrence === 0 || occurrenceCount <= kw.max_occurrence);
              return kw.keyword === word.Name && kw.speaker === message.type && inFirstSeconds && inLastSeconds && meetsOccurrence;
            });
            return matchedKeyword
              ? { ...word, category: matchedKeyword.category, speaker: matchedKeyword.speaker, reason: matchedKeyword }
              : { ...word, category: "Uncategorized", speaker: "Unknown", reason: null };
          });
          return { ...message, keywords: categorizedWords };
        });

        const result = categorizedMessages.map(message => ({
          display_name: message.display_name,
          categorized_keywords: message.keywords.filter(kw => kw.category !== "Uncategorized").map(kw => ({
            keyword: kw.Name,
            category: kw.category,
            speaker: kw.speaker,
            reason: kw.reason
          }))
        }));
        console.log("result", result);

        let _result = {};
        for (const item of result.filter(e => e.categorized_keywords.length > 0)) {
          if (!_result[item.categorized_keywords[0].category]) {
            _result[item.categorized_keywords[0].category] = { keywords: [] };
          }
          _result[item.categorized_keywords[0].category]["keywords"].push(item.categorized_keywords[0].keyword);
          _result[item.categorized_keywords[0].category]["keyword"] = Array.from(new Set(_result[item.categorized_keywords[0].category]["keywords"])).join(',');
        }


        return _result;
      } catch (error) {
        console.error("Error in categorizeMessages:", error);
      }
    },
    findAndTagOverlappingWords(messages) {
      messages.sort((a, b) => a.Start - b.Start);

      for (let i = 0; i < messages.length - 1; i++) {
        const words1 = messages[i].Words;
        for (let j = i + 1; j < messages.length; j++) {
          const words2 = messages[j].Words;

          words1.forEach(word1 => {
            words2.forEach(word2 => {
              if ((word1.Start <= word2.Start && word1.End >= word2.Start) ||
                (word2.Start <= word1.Start && word2.End >= word1.Start)) {
                word1.overlapping = true;
                word2.overlapping = true;
              }
            });
          });
        }
      }

      return messages;
    },
    isWordPlaying(word) {
      return this.currentTime >= word.Start && this.currentTime < word.End;
    },
    processDataSet(dataSet) {
      this.speechRegions = dataSet.Channels.flatMap(channel => channel.SpeechRegions);
    },
  },
  mounted: function () {
    this.selected_snapshot = this.snapshots.data[0];
    this.queues = globalThis.queues;
    console.log("this.selected_snapshot", this.selected_snapshot);
  },
}
</script>

<style lang="scss" scoped>
</style>
