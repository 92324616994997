<template>
  <div>
    <b-custom-spinner
      style="
        z-index: 999;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        overflow-y: hidden;
      "
      :is_progress="is_progress"
    ></b-custom-spinner>

    <b-tabs
      lazy
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab title="Pano">
        <b-card>
          <b-row>
            <b-col cols="2">
              <span>{{ lang("t_date") }}</span>
              <v-select
                class="mt-50"
                v-model="selected_date_filter_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="date_filter_types"
                @input="RefreshDataToShow"
              />
            </b-col>
            <b-col>
              <span>{{ lang("t_queue") }}</span>
              <v-select
                appendToBody
                class="mt-50"
                v-model="selected_merged_queues"
                :reduce="(option) => option"
                label="display_name"
                value="internal_name"
                :options="merged_queues"
                :placeholder="lang('t_queue')"
                multiple
                deselectFromDropdown
                :closeOnSelect="false"
                @input="RefreshDataToShow"
              >
                <template #option="{ internal_name, _type }">
                  <div class="d-flex align-items-center"></div>
                  <span v-if="_type == 'queue'" class="" style="margin: 0">{{
                    merged_queues.find((e) => e.internal_name == internal_name)
                      .display_name
                  }}</span>
                  <span
                    v-b-tooltip.hover.bottom="
                      queue_groups
                        .find((e) => e.internal_name)
                        .queues.map((e) => e.display_name)
                        .join(' - ')
                    "
                    v-else
                    class="font-weight-bolder"
                    style="margin: 0"
                    >{{
                      merged_queues.find(
                        (e) => e.internal_name == internal_name
                      ).display_name
                    }}</span
                  >
                  <small v-if="_type == 'queue_group'" class="text-muted ml-1">
                    {{ lang(`t_queueGroup`) }}</small
                  >
                </template>
                <template #list-header>
                  <div class="my-50 mx-50">
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="flat-secondary"
                      @click="
                        () => {
                          selected_merged_queues = merged_queues;
                          fetchRecords();
                        }
                      "
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" />
                      {{ lang("t_selectAll") }}
                    </b-button>
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="flat-secondary"
                      @click="
                        () => {
                          selected_merged_queues = [];
                          fetchRecords();
                        }
                      "
                    >
                      <feather-icon size="14" icon="XOctagonIcon" />
                      {{ lang("t_clear") }}
                    </b-button>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-card>

        <b-row class="mt-1 d-flex align-items-stretch">
          <b-col cols="5">
            <b-card no-body class="card-developer-meetup">
              <div class="bg-light-primary border rounded-top text-center">
                <b-row>
                  <b-col
                    class="border-right d-flex align-items-center justify-content-center"
                  >
                    <feather-icon
                      class="text-primary my-1"
                      size="28"
                      icon="HeadphonesIcon"
                    ></feather-icon>
                    <span class="ml-1">Temsilci</span>
                  </b-col>
                  <b-col
                    class="border-left d-flex align-items-center justify-content-center"
                  >
                    <feather-icon
                      class="text-primary my-1"
                      size="28"
                      icon="UsersIcon"
                    />
                    <span class="ml-1">Müşteri</span>
                  </b-col>
                </b-row>
              </div>
              <b-card-body>
                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgagentspeech || 0).toFixed(1)
                      }}
                    </h2>
                    <feather-icon
                      :class="
                        DataToShow.avgagentspeech > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgagentspeech > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Konuşma Oranı %</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      :class="
                        DataToShow.avgcustomerspeech > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgcustomerspeech > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgcustomerspeech || 0).toFixed(1)
                      }}
                    </h2>
                  </b-col>
                </b-row>

                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <!-- <feather-icon size="20" icon="SmileIcon"></feather-icon> -->

                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgagentsentiment || 0).toFixed(1)
                      }}
                    </h2>
                    <feather-icon
                      :class="
                        DataToShow.avgagentsentiment > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgagentsentiment > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Memnuniyet %</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      :class="
                        DataToShow.avgcustomersentiment > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgcustomersentiment > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(
                          DataToShow.avgcustomersentiment || 0
                        ).toFixed(1)
                      }}
                    </h2>
                    <!-- <feather-icon size="20" icon="MehIcon"></feather-icon> -->
                  </b-col>
                </b-row>

                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <!-- <feather-icon size="20" icon="Volume2Icon"></feather-icon> -->

                    <h2 class="my-0 mx-1">
                      {{ parseFloat(DataToShow.avgagentwords || 0).toFixed(1) }}
                    </h2>
                    <feather-icon
                      :class="
                        DataToShow.avgagentwords > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgagentwords > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Ort. Kelime Sayısı</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      :class="
                        DataToShow.avgcustomerwords > 50
                          ? 'text-success'
                          : 'text-danger'
                      "
                      :icon="
                        DataToShow.avgcustomerwords > 50
                          ? 'ArrowUpIcon'
                          : 'ArrowDownIcon'
                      "
                    >
                    </feather-icon>
                    <h2 class="my-0 mx-1">
                      {{
                        parseFloat(DataToShow.avgcustomerwords || 0).toFixed(1)
                      }}
                    </h2>
                    <!-- <feather-icon size="20" icon="VolumeIcon"></feather-icon> -->
                  </b-col>
                </b-row>

                <!-- metting header -->
                <!-- <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">THU</h6>
                <h3 class="mb-0">24</h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25"> Developer Meetup </b-card-title>
                <b-card-text class="mb-0">
                  Meet world popular developers
                </b-card-text>
              </div>
            </div> -->
                <!--/ metting header -->

                <!-- media -->
                <!-- <b-media v-for="media in mediaData" :key="media.avatar" no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon :icon="media.avatar" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  {{ media.title }}
                </h6>
                <small>{{ media.subtitle }}</small>
              </b-media-body>
            </b-media> -->
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="7">
            <b-card no-body class="card-statistics">
              <!-- <b-card-header>
                <b-card-title>
                  <h4 class="font-weight-bolder mb-0">
                    {{ "Konuşma Detayları" }}
                  </h4></b-card-title
                >
              </b-card-header> -->
              <b-card-body class="pb-0 align-items-center pt-1 pb-2">
                <b-row>
                  <b-col
                    v-for="item in statisticsItems2"
                    :key="item.icon"
                    md="4"
                    sm="6"
                    class="mt-auto mb-2 mb-md-0"
                    :class="item.customClass"
                  >
                    <b-media class="mt-2 mb-75" no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar rounded="lg" size="64" :variant="item.color">
                          <feather-icon size="28" :icon="item.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h2 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h2>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-for="item in statisticsItems"
                    :key="item.icon"
                    md="4"
                    sm="6"
                    class="mt-auto mb-2 mb-md-0"
                    :class="item.customClass"
                  >
                    <b-media class="mt-2 mb-75" no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar rounded="lg" size="64" :variant="item.color">
                          <feather-icon size="28" :icon="item.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h2 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h2>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <div class="d-flex align-items-center">
                <b-card-title>
                  {{ lang("t_keywordCategories") }}
                </b-card-title>
                <b-button
                  v-b-modal.modal-key-word
                  class="px-50 py-50 ml-auto"
                  variant="flat-secondary"
                >
                  <i class="bi bi-pencil font-medium-1"></i>
                </b-button>
              </div>

              <b-row>
                <b-col>
                  <swiper
                    class="swiper-autoplay"
                    :options="swiperOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <swiper-slide
                      style="overflow: hidden"
                      v-for="(data, index) in DataToShow.Categories"
                      :key="index"
                    >
                      <b-card
                        :class="
                          selected_keyword_category &&
                          data.Name == selected_keyword_category.Name
                            ? 'bg-light-primary'
                            : ''
                        "
                        @click="onClickKeywordCategory(data)"
                        class="border rounded-lg mb-0 cursor-pointer"
                        no-body
                      >
                        <b-card-body class="pb-0">
                          <div class="truncate text-center">
                            <h2 class="mb-25 font-weight-bolder text-center">
                              {{ data.count }}
                            </h2>
                            <span v-b-tooltip.hover.top="data.Name">{{
                              data.Name
                            }}</span>
                          </div>
                          <div class="text-center mb-1">
                            <b-badge variant="light-success">
                              {{
                                "%" +
                                parseFloat(
                                  (data.count / totalRows) * 100
                                ).toFixed(2)
                              }}
                            </b-badge>
                          </div>
                        </b-card-body>
                        <!-- <vue-apex-charts
                          v-if="chartOptionsComputedData[index]"
                          type="area"
                          height="50"
                          width="100%"
                          :options="chartOptionsComputed"
                          :series="[
                            {
                              name: 'data',
                              data: [76, 35, 78, 11, 42, 64, 86],
                            },
                          ]"
                        /> -->
                      </b-card>
                    </swiper-slide>

                    <div slot="pagination" class="swiper-pagination" />
                  </swiper>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col :cols="displaySound ? '8' : '12'">
            <b-card style="height: 64vh; overflow-y: auto" no-body class="">
              <b-row class="mx-0 mt-1">
                <b-col class="d-flex align-items-center">
                  <h4 class="my-0 pl-1">Kayıtlar</h4>
                </b-col>
                <b-col cols="8">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Konuşma, Müşteri Adı, Telefon vs..."
                    />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-card-body>
                <div style="display: grid; overflow-x: auto">
                  <b-table
                    :tbody-tr-class="rowClassRecords"
                    :filter="searchQuery"
                    style="
                      min-height: 32rem;
                      max-height: 32rem;
                      overflow: inherit;
                    "
                    small
                    @row-dblclicked="load_sound"
                    ref="refInvoiceListTable"
                    :items="records"
                    responsive
                    hover
                    :fields="tableColumns"
                    primary-key="id"
                    show-empty
                    empty-text="No matching records found"
                    class="position-relative mt-"
                  >
                    <template #cell(StationName)="data">
                      {{ data.value.split("_")[0] }}
                    </template>
                    <template #cell(StartDate)="data">
                      {{ data.value.replace("T", " ").slice(0, 19) }}
                    </template>
                    <template #cell(PhoneNumber)="data">
                      {{ data.value.slice(-10) }}
                    </template>
                    <template #cell(QueueName)="data">
                      <b-badge variant="light-primary">
                        {{
                          queues.find((e) => e.internal_name == data.value)
                            ? queues.find((e) => e.internal_name == data.value)
                                .display_name
                            : data.value
                        }}
                      </b-badge>
                    </template>

                    <template #cell(Inbound)="data">
                      <div class="d-flex align-items-center">
                        <b-icon
                          icon="telephone-inbound"
                          v-if="data.value == true"
                        ></b-icon>
                        <b-icon icon="telephone-outbound" v-else></b-icon>

                        <!-- <span class="ml-50">{{
                          data.value ? "inbound" : "outbound"
                        }}</span> -->
                      </div>
                    </template>
                    <template #cell(PbxCallId)="data">
                      {{ data.value.slice(0, 14) }}
                    </template>
                    <template #cell(AutoEvaluations)="data">
                      {{ getAutoEvaluationsName(data.value) }}
                    </template>
                    <template #cell(actions)="data">
                      <b-dropdown
                        boundary="scrollParent"
                        :popper-opts="{
                          modifiers: {
                            computeStyle: { gpuAcceleration: false },
                          },
                        }"
                        dropleft
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        toggle-class="py-50"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'email')"
                        >
                          <i class="bi bi-envelope"></i>
                          <span class="align-middle ml-50">E-Posta</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'add_info')"
                        >
                          <i class="bi bi-patch-plus"></i>
                          <span class="align-middle ml-50">Bilgi Ekle</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'special_info')"
                        >
                          <i class="bi bi-person-bounding-box"></i>
                          <span class="align-middle ml-50">Özel Bilgi</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'listens')"
                        >
                          <i class="bi bi-play-btn"></i>
                          <span class="align-middle ml-50">Dinlemeler</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'speech_analatics')"
                        >
                          <i class="bi bi-clipboard-data"></i>
                          <span class="align-middle ml-50"
                            >Konuşma İstatistikleri</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="
                            getCallDetails(data.item, 'sentimental_analatics')
                          "
                        >
                          <i class="bi bi-bar-chart"></i>
                          <span class="align-middle ml-50"
                            >Duygu İstatistikleri</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'media_analatics')"
                        >
                          <i class="bi bi-diagram-3"></i>
                          <span class="align-middle ml-50"
                            >Medya İstatistikleri</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="getCallDetails(data.item, 'signal_logs')"
                        >
                          <i class="bi bi-reception-2"></i>

                          <span class="align-middle ml-50"
                            >Sinyalleşme Logları</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </b-card-body>
              <b-card-footer class="py-75 border-0">
                <div
                  class="d-flex justify-content-between d-flex align-items-center"
                >
                  <div class="d-flex align-items-center mb-0">
                    <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                    <b-form-select
                      size="sm"
                      v-model="perPage"
                      :options="['10', '25', '50']"
                      class="mr-1"
                      @input="fetchRecords()"
                    />
                    <span class="text-nowrap text-muted font-small-3">
                      {{
                        lang("t_total") +
                        " " +
                        totalRows +
                        " " +
                        lang("t_row").toLowerCase()
                      }}
                    </span>
                  </div>

                  <b-pagination
                    @input="fetchRecords()"
                    size="sm"
                    class="mb-0"
                    v-model="currentPage"
                    :per-page="perPage"
                    align="right"
                    :total-rows="totalRows"
                    first-number
                    last-number
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="16" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="16" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
          <b-col v-if="displaySound" cols="4">
            <b-card
              ref="soundCard"
              style="height: 64vh; overflow-y: auto"
              no-body
              class="mb-5 border-bottom"
            >
              <div>
                <b-row v-if="selected_record" class="border-bottom mx-0">
                  <b-col>
                    <b-row align-h="between" align-v="center">
                      <b-col>
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <b-avatar
                            size="38"
                            variant="light-primary"
                            class="my-1"
                          >
                            {{
                              selected_record.StationName[0].toUpperCase() +
                              selected_record.StationName.split(
                                "."
                              )[1][0].toUpperCase()
                            }}
                          </b-avatar>
                          <!-- <span class="ml-1 font-weight-bold">{{
                          selected_record.StationName.split("_")[0]
                        }}</span> -->

                          <!-- <span>{{ displayKeyWordCategories() }}</span> -->
                          <b-dropdown
                            v-if="false"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                              Object.keys(categorized_messages).length == 0
                            "
                            class="ml-1"
                            size="sm"
                          >
                            <template #button-content>
                              <i class="bi bi-tags font-medium-5"></i>
                            </template>

                            <b-dropdown-item
                              @click="
                                searchKeyword =
                                  categorized_messages[item].keyword;
                                searchAndHighlightKeyword();
                              "
                              v-for="(item, i) in Object.keys(
                                categorized_messages
                              )"
                              :key="i"
                            >
                              {{ item }}
                            </b-dropdown-item>
                          </b-dropdown>

                          <b-form-input
                            size="sm"
                            class="mx-1 rounded"
                            v-model="searchKeyword"
                            @keyup="searchAndHighlightKeyword"
                          />
                          <span class="ml-1">
                            {{
                              totalMatches == 0 ? "0" : currentMatchIndex + 1
                            }}/{{ totalMatches }}
                          </span>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>

              <div>
                <!-- <div style="height: 70vh; overflow-y: auto"> -->
                <div ref="msg_div" class="messages mt-1">
                  <div v-for="(message, i) in messages" :key="i">
                    <b-row class="px-1">
                      <b-col>
                        <span
                          :class="[
                            message.type == 'customer'
                              ? 'msg_from bg-light-secondary'
                              : 'msg_to bg-light-primary',
                            checkKeywordCategory(message.Text) == true &&
                            message.type == 'customer'
                              ? 'border-secondary'
                              : checkKeywordCategory(message.Text) == true &&
                                message.type == 'agent'
                              ? 'border-primary'
                              : '',
                          ]"
                        >
                          <span
                            v-for="(word, wordIndex) in message.Words"
                            :key="wordIndex"
                            :class="{
                              highlight: isWordPlaying(word) || word.highlight,
                              'font-weight-bolder': word.overlapping,
                            }"
                          >
                            {{ word.Name }}
                          </span>

                          <small class="time">
                            <div
                              class="d-flex align-items-center justify-content-end gap-1"
                            >
                              <i
                                v-if="message.Sentiment.Result == 1"
                                class="bi bi-record-circle text-danger"
                              ></i>
                              <i
                                v-if="message.Sentiment.Result == 2"
                                class="bi bi-record-circle text-success ml-50"
                              ></i>
                              <i
                                v-if="message.HighGainDuration > 0"
                                class="bi bi-graph-up-arrow text-warning ml-50"
                              ></i>
                              <i
                                v-if="message.LowGainDuration > 0"
                                class="bi bi-graph-down-arrow text-danger ml-50"
                              ></i>
                              <span class="ml-1">{{ message.Start }}</span>
                            </div>
                          </small>
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-row
          :class="displaySound ? 'd-block' : 'd-none'"
          style="
            position: fixed;
            bottom: 0;
            z-index: 9999;
            left: 0;
            width: 100vw;
            margin-left: 0;
            margin-right: 0;
          "
        >
          <b-col class="px-0">
            <b-card class="mb-0">
              <b-card-text>
                <b-row class="mx-0">
                  <b-col
                    class="d-flex align-items-center justify-content-center px-0"
                  >
                    <b-dropdown
                      v-if="wavesurfer"
                      class="mr-50 ml-2"
                      :text="wavesurfer.getPlaybackRate() + 'x'"
                      variant="flat-secondary"
                    >
                      <b-dropdown-item @click="setSoundSpeed(0.25)">
                        0.25x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(0.5)">
                        0.5x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(0.75)">
                        0.75x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1)">
                        1x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1.25)">
                        1.25x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1.5)">
                        1.50x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(1.75)">
                        1.75x
                      </b-dropdown-item>
                      <b-dropdown-item @click="setSoundSpeed(2)">
                        2x
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="20" icon="SkipBackIcon" />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon rounded-circle mx-50"
                      @click="
                        () => {
                          wavesurfer.playPause();
                        }
                      "
                    >
                      <feather-icon
                        v-if="wavesurfer"
                        size="20"
                        :icon="
                          wavesurfer.isPlaying() ? 'PauseIcon' : 'PlayIcon'
                        "
                      />
                    </b-button>
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="20" icon="SkipForwardIcon" />
                    </b-button>
                    <span style="width: 3rem" class="ml-3">
                      {{ `${formatTime(parseInt(currentTime))}` }}
                    </span>
                    <b-col class="cursor-pointer" :id="`waveform`"></b-col>
                    <span
                      style="width: 3rem"
                      v-if="wavesurfer && wavesurfer.decodedData"
                    >
                      {{
                        `${formatTime(
                          parseInt(wavesurfer.decodedData.duration)
                        )}`
                      }}
                    </span>
                    <b-button
                      @click="closeSound"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle ml-5"
                    >
                      <feather-icon size="20" icon="XIcon" />
                    </b-button>
                  </b-col>
                </b-row>

                <!-- <div id="hover"></div> -->
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Kayıtlar">
        <find-records></find-records>
      </b-tab>
      <b-tab title="Trend Analizi">
        <trend-analize></trend-analize>
      </b-tab>
      <b-tab disabled title="Fark Analizi"></b-tab>
    </b-tabs>
    <b-modal
      body-class="my-0 py-0 mx-0 px-0"
      hide-footer
      hide-header
      id="modal-key-word"
      dialog-class="dClass75"
      centered
    >
      <key-word></key-word>
    </b-modal>
    <b-modal
      title="Sinyalleşme Logları"
      v-if="selected_record_details"
      v-model="modals.signal_logs"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.SignalingLogs"
          :fields="signal_logs_fields"
        >
          <template #cell(MessageDate)="data">
            {{ new Date(data.value).toLocaleTimeString() }}
          </template>
          <template #cell(Message)="data">
            <b-link @click="openSignalLogDetails(data.item)">{{
              data.item.Request ? data.item.Request : data.item.Response
            }}</b-link>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      title="Medya İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.media_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div
        class="d-flex align-items-center justify-content-center flex-wrap"
        style="gap: 8px"
      >
        <b-card
          v-for="(item, i) in [
            'RtpPacketCountIn',
            'RtpPacketCountOut',
            'DublicateRtpPacketCountIn',
            'DublicateRtpPacketCountOut',
            'LostRtpPacketCountIn',
            'LostRtpPacketCountOut',
            'FirstRtpPacketIn',
            'FirstRtpPacketOut',
            'LastRtpPacketIn',
            'LastRtpPacketOut',
            'CodecIn',
            'CodecOut',
            'RtpEncryptionIn',
            'RtpEncryptionOut',
          ]"
          class="bg-light-primary"
          no-body
          style="flex-basis: calc(100% / 7 - 8px)"
          :key="i"
        >
          <div class="text-center py-1 px-1">
            <span
              v-b-tooltip.hover.top="item"
              class="d-inline-block text-truncate"
              style="max-width: 99%"
              >{{ item }}</span
            >

            <h2
              v-if="
                [
                  'FirstRtpPacketIn',
                  'FirstRtpPacketOut',
                  'LastRtpPacketIn',
                  'LastRtpPacketOut',
                ].includes(item)
              "
            >
              {{
                new Date(
                  selected_record_details.MediaLog[item]
                ).toLocaleTimeString()
              }}
            </h2>
            <h2
              v-else-if="
                ['CodecIn', 'CodecOut'].includes(item) &&
                selected_record_details.MediaLog[item].length == 0
              "
            >
              {{ "-" }}
            </h2>

            <h2 v-else>
              {{ selected_record_details.MediaLog[item] }}
            </h2>
          </div>
        </b-card>
      </div>
      <div class="border-bottom">
        <h2 class="text-primary">Sources In</h2>
      </div>
      <b-row class="py-1">
        <b-col cols="6">
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].IpPortLen }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].FirstDate }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesIn[0].LastDate }}
          </span>
        </b-col>
      </b-row>
      <div class="border-bottom">
        <h2 class="text-primary">Sources Out</h2>
      </div>
      <b-row class="py-1">
        <b-col cols="6">
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].IpPortLen }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].FirstDate }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ selected_record_details.MediaLog.SourcesOut[0].LastDate }}
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Duygu İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.sentimental_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <b-row>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Temsilci</span
              >
              <small class="text-muted">Temsilci konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in sentiment_object"
              :key="i"
              class="mt-auto"
              :class="[
                [
                  'SentimentPositiveSegmentRate',
                  'SentimentNegativeSegmentRate',
                ].includes(item.internal_name)
                  ? 'mb-3'
                  : 'mb-1',
              ]"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[1][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Müşteri</span
              >
              <small class="text-muted">Müşteri konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in sentiment_object"
              :key="i"
              class="mt-auto"
              :class="[
                [
                  'SentimentPositiveSegmentRate',
                  'SentimentNegativeSegmentRate',
                ].includes(item.internal_name)
                  ? 'mb-3'
                  : 'mb-1',
              ]"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[0][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Konuşma İstatistikleri"
      v-if="selected_record_details"
      v-model="modals.speech_analatics"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <!-- <b-row class="d-flex align-items-center">
        <b-col class="d-flex align-items-center border rounded">
      
          <div class="d-flex align-items-center justify-content-center">
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Üstüste Konuşma Oranı</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Üstüste Konuşma Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Toplam Üstüste Konuşma Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
          </div>
        </b-col>
        <b-col class="d-flex align-items-center border rounded">
          <div class="d-flex align-items-center justify-content-center">
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 texspeecht-truncate" style="width: 99%"
                >Sessizlik Oranı</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Sessizlik Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
            <div class="d-block align-items-center justify-content-center mx-1">
              <span class="font-medium-1 text-truncate" style="width: 99%"
                >Toplam Sessizlik Süresi</span
              >
              <span class="font-large-1 font-weight-bolder d-block">10</span>
            </div>
          </div>
        </b-col>
      </b-row> -->

      <b-row v-if="selected_record_details">
        <b-col
          v-for="(item, i) in speech_object2"
          :key="i"
          md="2"
          sm="2"
          class="mt-auto mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media class="mt-2 mb-75" no-body>
            <b-media-aside class="mr-2">
              <b-avatar rounded="lg" size="64" :variant="item.color">
                <!-- <feather-icon size="28" :icon="item.icon" /> -->
                <i :class="[item.icon, 'font-medium-3']"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h2 class="font-weight-bolder mb-0">
                {{
                  parseInt(selected_record_details.Speech[item.internal_name])
                }}
              </h2>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Temsilci</span
              >
              <small class="text-muted">Temsilci konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in speech_object"
              :key="i"
              class="mt-auto mb-1"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[1][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
        <b-col>
          <b-card-body class="border rounded-lg">
            <div class="mb-1">
              <span class="d-block font-medium-3 font-weight-bolder"
                >Müşteri</span
              >
              <small class="text-muted">Müşteri konuşma istatistikleri</small>
            </div>

            <b-media
              v-for="(item, i) in speech_object"
              :key="i"
              class="mt-auto mb-1"
              no-body
            >
              <b-media-aside>
                <b-avatar
                  size="3rem"
                  rounded="lg"
                  :variant="`light-${item.color}`"
                >
                  <i class="font-medium-3" :class="item.icon"></i>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="d-flex align-items-center mr-2">
                <div class="d-block align-items-center justify-content-center">
                  <span
                    class="d-block font-medium-1 font-weight-bolder mb-0 text-truncate"
                  >
                    {{ item.display_name }}
                    <small class="text-muted d-block">Açıklama</small>
                  </span>
                </div>

                <span
                  class="ml-auto mb-0 font-medium-1 font-weight-bolder"
                  :class="[`text-${item.color}`]"
                >
                  {{
                    parseInt(
                      selected_record_details.Speech.Channels[0][
                        item.internal_name
                      ] || "0"
                    )
                  }}
                  <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                </span>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      title="Bilgi Ekle"
      v-if="selected_record_details"
      v-model="modals.add_info"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          style="max-height: 400px; overflow-y: auto"
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.Attachments"
          :fields="attechments_fields"
        >
          <template #cell(Date)="data">
            <span class="text-nowrap">
              {{
                new Date(new Date(data.value).getTime() + 3 * 60 * 60 * 1000)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")
              }}
            </span>
          </template>
        </b-table>
      </div>
      <hr />
      <b-row>
        <b-col cols="3">
          <b-form-group label="Saniye">
            <b-form-input
              v-model="attechment_info.Second"
              type="number"
              placeholder="Saniye"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group label="Yorum">
            <b-form-input
              v-model="attechment_info.Comment"
              placeholder="Yorum"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-1">
        <b-button @click="setAttachData" class="ml-auto" variant="primary"
          >Bilgi Ekle</b-button
        >
      </div>
    </b-modal>
    <b-modal
      title="Dinlemeler"
      v-if="selected_record_details"
      v-model="modals.listens"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <div style="display: grid; overflow-x: auto">
        <b-table
          style="max-height: 400px; overflow-y: auto"
          small
          responsive
          show-empty
          empty-text="No matching records found"
          :items="selected_record_details.Listens"
          :fields="listens_fields"
        >
          <template #cell(Date)="data">
            <span class="text-nowrap">
              {{
                new Date(new Date(data.value).getTime() + 3 * 60 * 60 * 1000)
                  .toISOString()
                  .replace(/([^T]+)T([^\.]+).*/g, "$1 $2")
              }}
            </span>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      title="Özel Bilgi"
      v-if="selected_record_details && customer"
      v-model="modals.special_info"
      hide-footer
      dialog-class="dClass75"
      centered
    >
      <customer-details :selected_customer="customer"></customer-details>
    </b-modal>
    <b-modal
      title="Email"
      v-if="selected_record_details"
      v-model="modals.email"
      dialog-class="dClass75"
      centered
      ok-only
      ok-title="Gönder"
      @ok="SendEmail"
    >
      <b-row class="mb-1">
        <!-- <b-col>
          <b-form-checkbox
            v-model="email_info.Me"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Kendim") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col> -->
        <b-col>
          <b-form-checkbox
            v-model="email_info.Agent"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("t_agent") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="email_info.SendLink"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Bağlantı") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="email_info.Details"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                {{ lang("Detaylar") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="CC">
            <b-form-input
              v-model="email_info.CC"
              placeholder="CC"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Konu">
            <b-form-input
              v-model="email_info.Subject"
              placeholder="Konu"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Açıklama">
            <b-form-textarea
              rows="5"
              v-model="email_info.Explanation"
              placeholder="Açıklama"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  VBTooltip,
  BTable,
  BButtonGroup,
  BFormInput,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
  BForm,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormRating,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BImg,
  BTabs, BTab,
  BAvatarGroup,
  BFormSelect,
  BCardFooter,
  BFormCheckbox,
  BFormTextarea

} from "bootstrap-vue";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

import ChartjsComponentLineChart from "../Components/ChartjsComponentLineChart.vue";
import BCardActions from "@core/components/b-card-actions/BCardActionsCallAnalize.vue";
import 'swiper/css/swiper.css'
import { $themeColors } from "@themeConfig";
import WaveSurfer from 'wavesurfer.js'
import KeyWord from '../KeyWordCategories/List.vue';
import CustomerDetails from './CustomerDetails.vue';
import FindRecords from './FindRecords.vue';
import TrendAnalize from './TrendAnalize/list/List.vue';

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js'
// import RegionsPlugin from 'https://unpkg.com/wavesurfer.js@beta/dist/plugins/regions.js'

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@beta'
console.log("WaveSurfer", WaveSurfer);
// const canvas = document.createElement('canvas')
// const ctx = canvas.getContext('2d')



export default {
  components: {
    TrendAnalize,
    FindRecords,
    BFormTextarea,
    BFormCheckbox,
    CustomerDetails,
    BFormGroup,
    BCardFooter,
    BFormSelect,
    BPagination,
    KeyWord,
    flatPickr,
    BTabs, BTab,
    BImg,
    BInputGroupPrepend,
    BAvatarGroup,
    BProgress,
    Swiper,
    SwiperSlide,
    BInputGroup,
    BLink,
    BDropdown,
    BDropdownItem,
    BForm,
    BCardActions,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    VBTooltip,
    BTable,
    BButtonGroup,
    BFormInput,
    VueApexCharts,
    BContainer,
    BRow,
    BCardBody,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
    BFormRating,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      selected_keyword_category: null,
      customer: null,
      all_queues: [],
      selected_merged_queues: [],
      merged_queues: [],
      modals: {
        email: false,
        add_info: false,
        special_info: false,
        listens: false,
        speech_analatics: false,
        sentimental_analatics: false,
        media_analatics: false,
        signal_logs: false,
        signal_log_details: false,
      },
      sentiment_object: [
        { "internal_name": "SentimentPositiveSegmentCount", "display_name": "Pozitif Segment Sayısı", "icon": "bi bi-emoji-smile", color: 'success' },
        { "internal_name": "SentimentPositiveScore", "display_name": "Pozitif Skor", "icon": "bi bi-graph-up", color: 'success' },
        { "internal_name": "SentimentPositiveTokenCount", "display_name": "Pozitif Token Sayısı", "icon": "bi bi-check", color: 'success' },
        { "internal_name": "SentimentPositiveSegmentRate", "display_name": "Pozitif Segment Oranı (%)", "icon": "bi bi-pie-chart", color: 'success' },
        { "internal_name": "SentimentNegativeSegmentCount", "display_name": "Negatif Segment Sayısı", "icon": "bi bi-emoji-frown", color: 'danger' },
        { "internal_name": "SentimentNegativeScore", "display_name": "Negatif Skor", "icon": "bi bi-graph-down", color: 'danger' },
        { "internal_name": "SentimentNegativeTokenCount", "display_name": "Negatif Token Sayısı", "icon": "bi bi-x", color: 'danger' },
        { "internal_name": "SentimentNegativeSegmentRate", "display_name": "Negatif Segment Oranı (%)", "icon": "bi bi-pie-chart", color: 'danger' },
        { "internal_name": "SentimentNotrSegmentRate", "display_name": "Nötr Segment Oranı (%)", "icon": "bi bi-dash", color: 'secondary' },
        { "internal_name": "SentimentTotalScore", "display_name": "Toplam Skor", "icon": "bi bi-123", color: 'info' },

      ],
      speech_object: [
        { "internal_name": "SpeechDuration", "display_name": "Konuşma Süresi", "icon": "bi bi-clock", "color": "danger" },
        { "internal_name": "SpeechRatio", "display_name": "Konuşma Oranı", "icon": "bi bi-bar-chart", "color": "success" },
        { "internal_name": "SpeechingRate", "display_name": "Konuşma Hızı (Kelime/Dakika)", "icon": "bi bi-speedometer2", "color": "success" },
        { "internal_name": "InterruptCount", "display_name": "Söz Kesme Sayısı", "icon": "bi bi-x-circle", "color": "success" },
        { "internal_name": "LowGainDuration", "display_name": "Monoton Konuşma Süresi", "icon": "bi bi-volume-down", "color": "success" },
        { "internal_name": "HighGainDuration", "display_name": "Ageresif Konuşma Süresi", "icon": "bi bi-exclamation-circle", "color": "danger" }
      ],

      speech_object2: [
        {
          "icon": "bi bi-bar-chart",
          "color": "light-primary",
          "title": "% 0",
          "subtitle": "Üstüste Konuşma Oranı",
          "customClass": "mb-2 mb-xl-0",
          "internal_name": "OverlappedSpeechRatio"
        },
        {
          "icon": "bi bi-clock",
          "color": "light-primary",
          "title": "% 12",
          "subtitle": "Üstüste Konuşma Süresi",
          "customClass": "mb-2 mb-sm-0",
          "internal_name": "OverlappedSpeechDuration"
        },
        {
          "icon": "bi bi-clock-history",
          "color": "light-primary",
          "title": "0",
          "subtitle": "Toplam Üstüste Konuşma Süresi",
          "customClass": "",
          "internal_name": "OverlappedSpeechDuration"
        },
        {
          "icon": "bi bi-activity",
          "color": "light-info",
          "title": "% 0",
          "subtitle": "Sessizlik Oranı",
          "customClass": "mb-2 mb-xl-0",
          "internal_name": "OverlappedSilenceRatio"
        },
        {
          "icon": "bi bi-hourglass-split",
          "color": "light-info",
          "title": "% 12",
          "subtitle": "Sessizlik Süresi",
          "customClass": "mb-2 mb-sm-0",
          "internal_name": "OverlappedSilenceDuration"
        },
        {
          "icon": "bi bi-hourglass-bottom",
          "color": "light-info",
          "title": "0",
          "subtitle": "Toplam Sessizlik Süresi",
          "customClass": "",
          "internal_name": "OverlappedSilenceDuration"
        }
      ],

      signal_logs_fields: [
        { key: 'MessageDate', label: 'MessageDate', sortable: true },
        { key: 'Message', label: 'Message', sortable: true },
        { key: 'From', label: 'From', sortable: true },
        { key: 'To', label: 'To', sortable: true },
        { key: 'Transport', label: 'Transport', sortable: true },
        { key: 'SrcIpPort', label: 'SrcIpPort', sortable: true },
        { key: 'DstIpPort', label: 'DstIpPort', sortable: true },
      ],
      listens_fields: [
        { key: 'Date', label: 'Tarih', sortable: true },
        { key: 'UserId', label: 'Temsilci', sortable: true },
      ],
      attechments_fields: [
        { key: 'Date', label: 'Tarih', sortable: true },
        { key: 'UserId', label: 'Temsilci', sortable: true },
        { key: 'Second', label: 'Saniye', sortable: true },
        { key: 'Comment', label: 'Yorum', sortable: true },
      ],
      attechment_info: {
        Comment: '',
        Second: 0
      },
      email_info: {
        Me: false,
        Agent: false,
        CC: '',
        Subject: 'Çağrı Kaydı Maili',
        SendLink: false,
        Explanation: '',
        Details: false,
      },
      date_filter_types: [
        {
          internal_name: "lastOneHour",
          display_name: globalThis._lang("t_lastOneHour"),
        },
        {
          internal_name: "lastThreeHours",
          display_name: globalThis._lang("t_lastThreeHours"),
        },
        {
          internal_name: "today",
          display_name: globalThis._lang("t_today"),
        },
        {
          internal_name: "yesterday",
          display_name: globalThis._lang("t_yesterday"),
        },
        {
          internal_name: "twoDaysAgo",
          display_name: globalThis._lang("t_twoDaysAgo"),
        },
        {
          internal_name: "threeDaysAgo",
          display_name: globalThis._lang("t_threeDaysAgo"),
        },
        {
          internal_name: "fourDaysAgo",
          display_name: globalThis._lang("t_fourDaysAgo"),
        },
        {
          internal_name: "fiveDaysAgo",
          display_name: globalThis._lang("t_fiveDaysAgo"),
        },
        {
          internal_name: "sixDaysAgo",
          display_name: globalThis._lang("t_sixDaysAgo"),
        },
        {
          internal_name: "lastWeek",
          display_name: globalThis._lang("t_lastWeek"),
        },
        {
          internal_name: "thisMonth",
          display_name: globalThis._lang("t_thisMonth"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      currentTime: 0,
      filter_values: {
        startDate: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        endDate: new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        selectedQueues: [],
      },
      selected_date_filter_type: "today",
      displaySound: false,
      swiperOptions: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,

        },
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '',
          clickable: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 6,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
      wavesurfer: null,
      keyword_categories: [],
      messages: [],
      tableColumns: [
        { key: 'Inbound', label: 'Yön', sortable: true, tdClass: 'text-nowrap' },
        { key: 'QueueName', label: 'Kuyruk', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StationName', label: 'Temsilci', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PbxCallId', label: 'Çağrı ID', sortable: true, tdClass: 'text-nowrap' },
        { key: 'PhoneNumber', label: 'Numara', sortable: true, tdClass: 'text-nowrap' },
        { key: 'StartDate', label: 'Tarih', sortable: true, tdClass: 'text-nowrap' },
        { key: 'SipKeyValue', label: 'Sonuç Kodu', sortable: true, tdClass: 'text-nowrap', },
        { key: 'Duration', label: 'Süre', sortable: true, tdClass: 'text-nowrap', },
        { key: 'AutoEvaluations', label: 'Değerlendirmeler', sortable: true, tdClass: 'text-nowrap', },
        { key: 'actions', label: '', thStyle: { width: '5vh' } },
      ],
      records: [

      ],
      chartOptionsComputedData: [
        [
          {
            "name": "data",
            "data": [
              76,
              35,
              78,
              11,
              42,
              64,
              86
            ]
          }
        ],
        [
          {
            "name": "data1",
            "data": [
              9,
              7,
              96,
              74,
              54,
              47,
              37
            ]
          }
        ],
        [
          {
            "name": "data2",
            "data": [
              82,
              81,
              76,
              73,
              62,
              38,
              12
            ]
          }
        ],
        [
          {
            "name": "data3",
            "data": [
              32,
              96,
              80,
              73,
              39,
              88,
              40
            ]
          }
        ],
        [
          {
            "name": "data4",
            "data": [
              56,
              16,
              82,
              92,
              33,
              14,
              24
            ]
          }
        ],
        [
          {
            "name": "data5",
            "data": [
              52,
              39,
              62,
              1,
              42,
              32,
              92
            ]
          }
        ]
      ],

      chartOptionsComputed: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: $themeColors.secondary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      statisticsItems2: [
        {
          icon: 'ActivityIcon',
          color: 'light-success',
          title: '% 0',
          subtitle: 'Analiz Edilen',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ClockIcon',
          color: 'light-info',
          title: '% 12',
          subtitle: 'Ortalama Çağrı Süresi',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Otomatik Kalite Puanı',
          customClass: '',
        },
      ],
      statisticsItems: [
        {
          icon: 'MessageCircleIcon',
          color: 'light-warning',
          title: '% 0',
          subtitle: 'Üst Üste Konuşma Oranı',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-secondary',
          title: '% 0',
          subtitle: 'Sessizlik Oranı',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'XOctagonIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Temsilci Söz Kesme Sayısı',
          customClass: '',
        },
      ],
      projects: [],
      queues: [],
      selected_queue_group: [],
      queue_groups: [],
      selected_queues: [],
      selected_project: "",
      visible_users: [],
      finish_codes: [],
      dashboard_data: [],
      bar_charts: [],
      full_screen: false,
      fields: [
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true, tdClass: 'text-nowrap' },
        {
          key: "Productivity",
          label: globalThis._lang("t_productivity"),
          sortable: true,
        },
        {
          key: "Inbound",
          label: globalThis._lang("t_inbound"),
          sortable: true,
        },
        {
          key: "Outbound",
          label: globalThis._lang("t_outbound"),
          sortable: true,
        },
        { key: "Dialer", label: globalThis._lang("t_dialer"), sortable: true },
        {
          key: "MissedCalls",
          label: globalThis._lang("t_missedCall"),
          sortable: true,
        },
        // { key: 'AvgCallTime', label: globalThis._lang('t_avgCallTime'), sortable: true },
      ],
      tableData: [],
      speechRegions: [],
      searchQuery: "",
      interval: "",
      DataToShow: {},
      selected_record: null,
      categorized_messages: null,
      searchKeyword: '',
      currentMatchIndex: 0,
      totalMatches: 0,
      matchIndices: [],
      selected_record_details: null
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    getAutoEvaluationsName(data) {
      if (data && data.length > 0) {
        let results = [];
        data.forEach((evaluation, index) => {
          let formPoint = evaluation.Form.Point;
          let result = `O:${index + 1}-${formPoint}`;
          results.push(result);
        });
        return results;
      }
      return '';

    },
    openSignalLogDetails(item) {
      this.$swal({
        title: item.Request ? item.Request : item.Response,
        width: 750,
        html: '<pre style="text-align:left;width:100%;height:300px;">' + item.Message + '</pre>',
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

    },
    async getCallDetails(item, modal) {
      this.is_progress = true;
      let _month = new Date(item.StartDate).getMonth()
      var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
      this.selected_record_details = response;
      this.selected_record = item;
      if (modal) {
        if (modal == 'special_info') {
          await this.GetCustomerByInteractionID(item);
        }
        this.modals[modal] = true;
      }
      this.is_progress = false;
    },
    async GetCustomerByInteractionID(item) {
      var response = (await this.$http_in.get(`spv/v1/Customer/${item.PbxCallId}`)).data;
      this.customer = response;
    },
    async setAttachData() {
      let _month = new Date(this.selected_record.StartDate).getMonth()
      var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/AttachData/${this.selected_record._id}/${_month}`, this.attechment_info)).data;
      if (response.ResultCode == 0) {
        this.getCallDetails(this.selected_record);
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.attechment_info = {
        Second: 0,
        Comment: ''
      };

    },
    async SendEmail() {
      var call_details = (await this.$http_in.get(`spv/v1/CallDetails/${this.selected_record.PbxCallId}`)).data;
      let _url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${call_details.RecordingFile}`;
      let _month = new Date(this.selected_record.StartDate).getMonth();
      var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/SendMail/${this.selected_record._id}/${_month}`, {
        ...this.email_info,
        "Link": _url,
        "Organizations": false,
        "OrganizationId": this.selected_record.Station.OrganizationId,
        "Organization": this.selected_record.Station.OrganizationName,
        "UserId": this.selected_record.Station.Name,
        "Duration": this.selected_record.Duration,
        "QueueExtension": this.selected_record.QueueExtension,
        "StartDate": this.selected_record.StartDate
      })).data;
      if (response.ResultCode == 0) {
        this.getCallDetails(this.selected_record);
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.email_info = {
        Me: true,
        Agent: false,
        CC: '',
        Subject: 'Çağrı Kaydı Maili',
        SendLink: false,
        Explanation: '',
        Details: false,
      };

    },
    searchAndHighlightKeyword(event) {
      if (this.searchKeyword === '') {
        this.clearHighlights();
        return;
      }

      if (event && event.key === 'Enter') {
        if (this.totalMatches > 0) {
          this.currentMatchIndex = (this.currentMatchIndex + 1) % this.totalMatches;
          this.highlightAndScrollToCurrentMatch();
        }
        return;
      }

      this.clearHighlights();

      const keywords = this.searchKeyword.toLowerCase().split(',').filter(e => e.trim() !== '');

      // Find matches
      this.matchIndices = [];
      keywords.forEach(keyword => {
        this.messages.forEach((message, messageIndex) => {
          message.Words.forEach((word, wordIndex) => {
            if (word.Name.toLowerCase() == keyword.trim()) {
              this.$set(word, 'highlight', true);
              this.matchIndices.push({ messageIndex, wordIndex });
            }
          });
        });
      });

      this.totalMatches = this.matchIndices.length;

      if (this.totalMatches > 0) {
        this.currentMatchIndex = 0;
        this.highlightAndScrollToCurrentMatch();
      }
    },

    clearHighlights() {
      this.messages.forEach(message => {
        message.Words.forEach(word => {
          word.highlight = false;
        });
      });
      this.matchIndices = [];
      this.totalMatches = 0;
      this.currentMatchIndex = 0;
    },

    highlightAndScrollToCurrentMatch() {
      if (this.totalMatches > 0) {
        const { messageIndex, wordIndex } = this.matchIndices[this.currentMatchIndex];
        this.scrollToWord(messageIndex, wordIndex);
      }
    },

    scrollToWord(messageIndex, wordIndex) {
      this.$nextTick(() => {
        const messageDiv = this.$refs.msg_div;
        const targetMessages = messageDiv.querySelectorAll('.msg_from, .msg_to');
        const targetMessage = targetMessages[messageIndex];
        const targetWords = targetMessage.querySelectorAll('span');
        const targetWord = targetWords[wordIndex];

        if (targetMessage && targetWord) {
          console.log(`Scrolling to message ${messageIndex}, word ${wordIndex}`);
          console.log(`Message: ${targetMessage.innerText}`);
          console.log(`Word: ${targetWord.innerText}`);

          setTimeout(() => {
            targetWord.scrollIntoView({ behavior: 'smooth', block: 'center', });
          }, 100);
        } else {
          console.warn('Target message or word not found:', targetMessage, targetWord);
        }
      });
    },


    rowClassRecords(item) {
      console.log("item", item);
      if (this.selected_record && this.selected_record.PbxCallId == item.PbxCallId) {
        return "table-primary table-style text-dark font-weight-bolder"
      }

    },

    categorizeMessages() {
      console.log("categorizeMessages called"); // Kontrollü adım
      try {
        const allKeywords = this.keyword_categories.flatMap(rule => {
          return rule.rules.flatMap(r => {
            return r.keywords.map(keyword => ({
              ...keyword,
              category: rule.display_name,
            }));
          });
        });

        const categorizedMessages = this.messages.map(message => {
          const categorizedWords = message.Words.map(word => {
            const matchedKeyword = allKeywords.find(kw => {
              const inFirstSeconds = kw.in_first_seconds === 0 || word.Start <= kw.in_first_seconds;
              const inLastSeconds = kw.in_last_seconds === 0 || word.End >= kw.in_last_seconds;
              const occurrenceCount = message.Words.filter(w => w.Name === kw.keyword).length;
              const meetsOccurrence =
                (kw.min_occurrence === 0 || occurrenceCount >= kw.min_occurrence) &&
                (kw.max_occurrence === 0 || occurrenceCount <= kw.max_occurrence);
              return kw.keyword === word.Name && kw.speaker === message.type && inFirstSeconds && inLastSeconds && meetsOccurrence;
            });
            return matchedKeyword
              ? { ...word, category: matchedKeyword.category, speaker: matchedKeyword.speaker, reason: matchedKeyword }
              : { ...word, category: "Uncategorized", speaker: "Unknown", reason: null };
          });
          return { ...message, keywords: categorizedWords };
        });

        const result = categorizedMessages.map(message => ({
          display_name: message.display_name,
          categorized_keywords: message.keywords.filter(kw => kw.category !== "Uncategorized").map(kw => ({
            keyword: kw.Name,
            category: kw.category,
            speaker: kw.speaker,
            reason: kw.reason
          }))
        }));
        console.log("result", result);

        let _result = {};
        for (const item of result.filter(e => e.categorized_keywords.length > 0)) {
          if (!_result[item.categorized_keywords[0].category]) {
            _result[item.categorized_keywords[0].category] = { keywords: [] };
          }
          _result[item.categorized_keywords[0].category]["keywords"].push(item.categorized_keywords[0].keyword);
          _result[item.categorized_keywords[0].category]["keyword"] = Array.from(new Set(_result[item.categorized_keywords[0].category]["keywords"])).join(',');
        }


        return _result;
      } catch (error) {
        console.error("Error in categorizeMessages:", error);
      }
    },

    findAndTagOverlappingWords(messages) {
      messages.sort((a, b) => a.Start - b.Start);

      for (let i = 0; i < messages.length - 1; i++) {
        const words1 = messages[i].Words;
        for (let j = i + 1; j < messages.length; j++) {
          const words2 = messages[j].Words;

          words1.forEach(word1 => {
            words2.forEach(word2 => {
              if ((word1.Start <= word2.Start && word1.End >= word2.Start) ||
                (word2.Start <= word1.Start && word2.End >= word1.Start)) {
                word1.overlapping = true;
                word2.overlapping = true;
              }
            });
          });
        }
      }

      return messages;
    },
    isWordPlaying(word) {
      return this.currentTime >= word.Start && this.currentTime < word.End;
    },
    processDataSet(dataSet) {
      this.speechRegions = dataSet.Channels.flatMap(channel => channel.SpeechRegions);
    },
    closeSound() {
      if (this.displaySound) {
        this.displaySound = false;
        var myDiv = document.getElementById(`waveform`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
        this.selected_record = null;
        this.matchIndices = [];
        this.totalMatches = 0;
        this.currentMatchIndex = 0;
        this.searchKeyword = '';

      }

    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },

    getStartEndDates(period) {
      const now = new Date();
      let start, end;

      switch (period) {
        case 'lastOneHour':
          start = new Date(now.getTime() - 60 * 60 * 1000);
          end = now;
          break;
        case 'lastThreeHours':
          start = new Date(now.getTime() - 3 * 60 * 60 * 1000);
          end = now;
          break;
        case 'today':
          start = new Date(now.setHours(0, 0, 0, 0));
          end = new Date(now.setHours(23, 59, 59, 999));
          break;
        case 'yesterday':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
          break;
        case 'twoDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'threeDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'fourDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 3);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'fiveDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 4);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'sixDaysAgo':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 5);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          break;
        case 'lastWeek':
          const dayOfWeek = now.getDay();
          start = new Date(now.setDate(now.getDate() - dayOfWeek - 6));
          start.setHours(0, 0, 0, 0);
          end = new Date(start.getTime() + 6 * 24 * 60 * 60 * 1000);
          end.setHours(23, 59, 59, 999);
          break;
        case 'thisMonth':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
          break;
        default:
          throw new Error('Invalid period');
      }

      return { start: this.formatDate(start), end: this.formatDate(end) };
    },
    formatDate(date) {
      // return date;
      // return date.toISOString().replace('T', ' ').slice(0, 19);
      return date.toISOString();
    },

    async fetchRecords() {
      if (this.selected_merged_queues.length == 0) {
        this.selected_merged_queues = this.merged_queues.filter(e => e._type == 'queue');
      }

      let date_range = this.getStartEndDates(this.selected_date_filter_type);
      let _payload = {
        offset: (this.currentPage - 1) * this.perPage,
        amount: this.perPage,
        StartDate: date_range.start,
        EndDate: date_range.end,
        Queue: this.selected_merged_queues.map(e => e.display_name).join('|'),
      };
      if (this.selected_keyword_category) {
        _payload["KeyWordSpottings"] = this.selected_keyword_category?.Id;
      }
      var response = (await this.$http_in.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetRecords`, _payload)).data;
      console.log(response);
      this.records = response.data.map(e => { return { ...e, StationName: e.Station.Name } });
      this.totalRows = response.recordsTotal;
    },

    onClickKeywordCategory(data) {
      console.log("data", data);
      if (this.selected_keyword_category && data.Name == this.selected_keyword_category.Name) {
        this.selected_keyword_category = null;
        this.fetchRecords();
        return;
      }
      this.selected_keyword_category = data;
      this.fetchRecords();

    },
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    },
    checkKeywordCategory(word) {
      for (const _word of word.split(' ')) {
        if (this.SpeechRules) {
          let object = this.SpeechRules.filter(e => e.Result == true).find(r => r.KeyWords.includes(_word));
          // console.log("checkKeywordCategory", _word, object);
          if (object) {
            console.log("GELDİ")
            return true
            // return this.dashboard_data["ByCategory"][object.Id].Name
          }
        }

      }

      return false;

    },
    displayKeyWordCategories() {
      return this.categorized_messages
        .filter(item => item.categorized_keywords.length > 0)
        .map(item => item.categorized_keywords[0].category);
    },
    async load_sound(item) {
      try {
        this.is_progress = true;
        this.closeSound();
        this.selected_record = item;
        console.log("item", item);
        let _month = new Date(item.StartDate).getMonth()
        var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/GetDetails/${item._id}/${_month}`)).data;
        console.log("response", response);
        this.SpeechRules = response.SpeechRules;

        this.messages = [];
        this.messages = response.Speech.Channels[0].SpeechRegions.map(e => { return { ...e, type: 'customer' } });
        this.messages = [...this.messages, ...response.Speech.Channels[1].SpeechRegions.map(e => { return { ...e, type: 'agent' } })];
        this.messages = this.messages.sort((a, b) => a.Start - b.Start);
        this.messages = this.findAndTagOverlappingWords(this.messages);
        this.categorized_messages = this.categorizeMessages();
        // console.log("_debug", _debug);
        this.displaySound = !this.displaySound;
        if (this.displaySound) {
          this.wavesurfer = WaveSurfer.create({

            container: '#waveform',
            waveColor: '#F0F0F1',
            progressColor: $themeColors.primary,
            url: response.RecordingFileName,
            // url: 'https://recordings.wrtcdev.voyce.cc/recordings/2023/08/19/voyce/out-1692430598.369-5445226155.ogg',
            // splitChannels: [
            //   {
            //     waveColor: '#F0F0F1',
            //     progressColor: $themeColors.secondary,
            //   },
            //   {
            //     waveColor: '#F0F0F1',
            //     progressColor: $themeColors.primary,
            //   },
            // ],
            // Set a bar width
            "height": 60,

            barWidth: 4,
            barGap: 3,
            barRadius: 8,
          })
          // let audio = new Audio();
          // audio.src = response.RecordingFile;
          // this.wavesurfer.load(audio);
          // this.wavesurfer.loadBlob(this.base64ToArrayBuffer(response.RecordingFile));
          // const base64String = response.RecordingFile;
          // const audioBlob = this.base64ToBlob(base64String, 'audio/wav');
          // this.wavesurfer.loadBlob(audioBlob);

          console.log("wavesurfer", this.wavesurfer)

          this.wavesurfer.once('interaction', () => {
            console.log("interaction")
            this.wavesurfer.play()
          })


          this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);
        } else {
          var myDiv = document.getElementById("waveform");
          myDiv.innerHTML = null;
          this.wavesurfer = null;
          this.currentTime = 0;
        }
      } catch (error) {
      } finally {
        this.is_progress = false;
      }

    },
    date_filter_type_changed() {
      if (this.selected_date_filter_type == "today") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "yesterday") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_7_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          7 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_30_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "this_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      this.RefreshDataToShow();
    },
    async RefreshDataToShow() {
      try {
        this.is_progress = true;
        await this.fetchRecords();

        await this.GetDashboardData();

        if (this.dashboard_data["ByDate"][this.selected_date_filter_type]) {
          this.DataToShow = this.dashboard_data["ByDate"][this.selected_date_filter_type];
          for (let category of this.DataToShow.Categories) {
            if (category.Result.length > 0) {
              category.Result = category.Result.filter(m => {
                if (Array.isArray(m)) {
                  return m.length > 0
                }
                return true;
              })
            }
          }

          this.DataToShow.Categories = this.DataToShow.Categories.filter(category => category.Result.length > 0);
          this.statisticsItems[0].title = "% " + parseFloat((this.DataToShow.avgcrosstalkduration / this.DataToShow.avgduration * 100) || 0).toFixed(1);
          this.statisticsItems[1].title = "% " + parseFloat((this.DataToShow.avgsilenceduration / this.DataToShow.avgduration * 100) || 0).toFixed(1);
          this.statisticsItems[2].title = parseFloat(this.DataToShow.avginterruptcount || 0).toFixed(1);

          this.statisticsItems2[0].title = this.DataToShow.count || 0;
          this.statisticsItems2[1].title = parseFloat(this.DataToShow.avgduration || 0).toFixed(1);
          this.statisticsItems2[2].title = parseFloat(this.DataToShow.avgqualitypoint || 0).toFixed(1);
          console.log(this.DataToShow.Result);
        }
      }
      finally {

        this.is_progress = false;

      }


    },
    formatSeconds(seconds) {
      var minutes = Math.floor(seconds / 60);
      var remainingSeconds = seconds % 60;

      // Pad the minutes and seconds with leading zeros, if required
      var paddedMinutes = String(minutes).padStart(1, "0");
      var paddedSeconds = String(remainingSeconds).padStart(1, "0");

      if (minutes > 0) {
        return `${paddedMinutes} min ${paddedSeconds} sec`;
      } else {
        return `${paddedSeconds} sec`;
      }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      // this.get_live_agent();
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      let default_push = this.selected_queues.length > 0 ? false : true;
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          this.merged_queues.push({
            ...item,
            _type: 'queue'
          })

          if (default_push) {
            this.selected_merged_queues.push({ ...item, _type: 'queue' });
            // this.selected_queues.push(item.internal_name);
          }
        }
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/spv/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      let _queue_group = response.map(function (obj) {
        return { ...obj, _type: "queue_group" };
      });
      this.merged_queues = [...this.merged_queues, ..._queue_group];


      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)

      this.selected_queues = Array.from(new Set([...this.selected_queues, ...queues]));
      this.getLiveQueue();
      this.getCallBackSuccessCount();
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetKeyWordCategories: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `/spv/v1/KeywordCategory`
        )
      ).data;

      this.keyword_categories = response;
      this.is_progress = false;
    },
    GetDashboardData: async function () {
      try {
        // var response = (await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/Dashboard/${this.selected_date_filter_type}`)).data;
        var response = (await this.$http_ex.post(`${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace('api', 'integration-callexper')}/callexper/v1/DashboardByQueues`, {
          time: this.selected_date_filter_type,
          queues: this.selected_merged_queues.map(e => e.display_name)
        })).data;
        this.dashboard_data = response;
      } catch (error) {

      }

    }
  },
  mounted: async function () {

    try {
      let _self = this;
      this.is_progress = true;
      this.finish_codes = globalThis.finish_codes;
      await this.get_users();

      await this.GetQueues();
      await this.GetQueueGroup();
      await this.GetKeyWordCategories();

      await this.RefreshDataToShow();
      await this.fetchRecords();
    } catch (error) {

    } finally {
      this.is_progress = false;

    }

    // await this.GetProjects();
    // await this.get_live_agent();

    // await this.GetDashboardData();

    // this.interval = setInterval(() => {
    //   _self.get_inbound_statistics();
    //   _self.get_live_agent();
    // }, 60000);
  },
  beforeDestroy() {
    // localStorage.setItem("dashboardData", JSON.stringify(this.$data));

    // clearInterval(this.interval);
  },
};
</script>

<style>
/* @import "@core/scss/vue/libs/vue-flatpicker.scss"; */
#waveform {
  cursor: pointer;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#waveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.highlight {
  background-color: yellow;
}

.custom-dropdown-menu {
  position: absolute;
  top: 0; /* Adjust this value based on your layout */
  left: 0; /* Adjust this value based on your layout */
  z-index: 1050; /* Bootstrap's dropdown z-index value */
  max-height: 300px; /* Set a maximum height to enable scrolling */
  overflow-y: auto; /* Enable scrolling */
}
</style>
